import React, { Component } from 'react';
import axios from 'axios';
import {
  Button,
  Row,
  Col,
  Form,
  Divider,
  Alert,
  message,
  Checkbox,
} from 'antd';
import '../../../../styles/App.css';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { trackFSEvent } from '../../../../helpers/fullstory';
import { PageButtonHeader } from '../../../../components/CustomHeader';

const FormItem = Form.Item;

export class StripePayment extends Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      labelCostPayment: undefined,
      enableStripePayment: false,
      manualLabelCostPayment: undefined,
      autoAcceptWithCovered: false,
      loading: true,
    };
  }

  async fetchReturnSettings() {
    try {
      const response = await axios.get('/api/returnSettings');
      if (response.data === null) {
        return message.error(
          'Error retrieving your payment settings. Try again.',
          5
        );
      }
      this.setState({
        ...response.data,
      });
    } catch (err) {
      message.error('Error retrieving your stripe settings. Try again.', 5);
    }
  }

  async componentDidMount() {
    try {
      await this.fetchReturnSettings();
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      message.error('Error retrieving your stripe settings. Try again.', 5);
    }
  }

  handleChange = (e) => {
    this.setState({
      [e.target.id]: e.target.checked,
    });
  };

  handleSubmitForm = (e) => {
    e.preventDefault();
    this.props.form.validateFields(async (err, values) => {
      if (err) {
        return message.error(
          'There is something wrong with the data you entered. Please check again.',
          5
        );
      }
      try {
        const response = await axios.post('/api/returnSettings/update', {
          ...values,
        });
        if (response.data.status === 'error') {
          return message.error(
            'Error updating your payment settings. Please try again',
            5
          );
        }
        this.trackEvents(values);
        return message.success('Payment settings successfully updated.', 5);
      } catch (err) {
        return message.error(
          'Error updating your payment settings. Please try again.',
          5
        );
      }
    });
  };

  trackEvents = (values) => {
    trackFSEvent('Change Payment Settings', {
      feature: 'Payment',
      enableStripePayments: values.enableStripePayment || false,
      enablePaymentsForLabelCosts: values.labelCostPayment || false,
      enableManualLabelCostPayment: values.manualLabelCostPayment || false,
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }
    return (
      <React.Fragment>
        {this.context && this.context.featureStripePayment ? (
          <Form onSubmit={this.handleSubmitForm} className="removeFormMargin">
            <PageButtonHeader
              headingTitle={'Payments'}
              headingRightContent={
                <FormItem>
                  <Button type="primary" htmlType="submit">
                    Save changes
                  </Button>
                </FormItem>
              }
            />
            <Row type="flex" justify="space-around" align="middle">
              <Col span={24}>
                <Alert
                  message="Setup Stripe Payments"
                  description={
                    <span>
                      Enter your Credentials to setup Stripe Payments. You can
                      now use our dedicated Stripe integration to charge
                      customers when they're trying to exchange for a higher
                      priced item or if you're looking to charge the label cost.
                      Learn more{' '}
                      <a
                        href="https://support.richreturns.io/en/articles/9272043-stripe-integration"
                        target="_blank"
                      >
                        here
                      </a>
                    </span>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
            <Row type="flex" align="top">
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            <div
              className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
              style={{ gap: 20, width: '100%' }}
            >
              <div
                className="flex-col-md-3 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <h4>Enable Stripe payments</h4>
                <span>
                  Check this box if you want to enable payments through stripe.
                  This will alow you to charge customers for higher-priced items
                  or label costs.
                </span>
              </div>
              <div
                className="flex-col-md-8 flex-col-sm-12"
                style={{ padding: 0, width: '100%' }}
              >
                <div className="ContentPanel">
                  <h4>Enable Stripe payments</h4>
                  <div
                    className="flex-row flex-row--noMargin"
                    style={{ width: '100%', gap: 10 }}
                  >
                    <FormItem>
                      {getFieldDecorator('enableStripePayment')(
                        <Checkbox
                          checked={this.state.enableStripePayment}
                          onChange={this.handleChange}
                        >
                          Enable
                        </Checkbox>
                      )}
                    </FormItem>
                  </div>
                </div>
              </div>
            </div>
            <Row type="flex" align="top">
              <Col span={24}>
                <Divider />
              </Col>
            </Row>
            {this.state.enableStripePayment && (
              <React.Fragment>
                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Enable payments for label costs</h4>
                    <span>
                      Check this box if you want your customers to pay for label
                      costs. Only applicable if you're automatically approving
                      returns or you have a prepaid label fee enabled in your
                      Return settings. Will be applied to all returns.
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <h4>Enable label payment costs</h4>
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%', gap: 10 }}
                      >
                        <FormItem>
                          {getFieldDecorator('labelCostPayment')(
                            <Checkbox
                              checked={this.state.labelCostPayment}
                              onChange={this.handleChange}
                            >
                              Enable
                            </Checkbox>
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>

                <Row type="flex" align="top">
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>

                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Enable Manual Label Cost Payment</h4>
                    <span>
                      Check this box if you want your customers to pay for label
                      if Pre-Paid Return Label (manual) is enabled. Only
                      applicable if prepaid label fee is enabled in Return
                      settings.
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <h4>Enable manual label cost payment</h4>
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%', gap: 10 }}
                      >
                        <FormItem>
                          {getFieldDecorator('manualLabelCostPayment')(
                            <Checkbox
                              checked={this.state.manualLabelCostPayment}
                              onChange={this.handleChange}
                            >
                              Enable
                            </Checkbox>
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>

                <Row type="flex" align="top">
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>

                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Enable discount deductions</h4>
                    <span>
                      Check this box if you want to deduct the discount from
                      exchanged product price as well.
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <h4>Enable discount deductions</h4>
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%', gap: 10 }}
                      >
                        <FormItem>
                          {getFieldDecorator('deductDiscounts')(
                            <Checkbox
                              checked={this.state.deductDiscounts}
                              onChange={this.handleChange}
                            >
                              Enable
                            </Checkbox>
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>

                <Row type="flex" align="top">
                  <Col span={24}>
                    <Divider />
                  </Col>
                </Row>

                <div
                  className="flex-row flex-row--noMargin flex-top-xxs flex-between-xxs"
                  style={{ gap: 20, width: '100%' }}
                >
                  <div
                    className="flex-col-md-3 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <h4>Mark returns with unfulfilled payments as pending</h4>
                    <span>
                      Even if you're automatically accepting all returns, a
                      return will always be marked as pending if the label fee
                      or price difference was not covered. The decision to
                      approve or not can be made later.
                    </span>
                  </div>
                  <div
                    className="flex-col-md-8 flex-col-sm-12"
                    style={{ padding: 0, width: '100%' }}
                  >
                    <div className="ContentPanel">
                      <h4>Mark unfulfilled returns as pending</h4>
                      <div
                        className="flex-row flex-row--noMargin"
                        style={{ width: '100%', gap: 10 }}
                      >
                        <FormItem>
                          {getFieldDecorator('autoAcceptWithCovered')(
                            <Checkbox
                              checked={this.state.autoAcceptWithCovered}
                              onChange={this.handleChange}
                            >
                              Enable
                            </Checkbox>
                          )}
                        </FormItem>
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}

            <div
              className="flex-row flex-row--noMargin flex-end-xxs u-marginBottom--lg"
              style={{ marginTop: 30 }}
            >
              <FormItem>
                <Button type="primary" htmlType="submit">
                  Save changes
                </Button>
              </FormItem>
            </div>
          </Form>
        ) : (
          <React.Fragment>
            <PageButtonHeader headingTitle={'Payments'} />
            <p>
              Let your customers easily and securely pay for their orders with
              Stripe.
            </p>
            <React.Fragment>
              <Row type="flex" justify="space-around" align="top">
                <Col span={24}>
                  <Alert
                    message="Looking to enable payments through Rich Returns?"
                    description={
                      <React.Fragment>
                        <span>
                          Go to the <a href="/dashboard/connect/platform">integrations area </a> 
                          and activate the Stripe integration to charge customers when they are
                          trying to exchange for a higher-priced item or if you want to charge 
                          the cost of the label.
                        </span>
                      </React.Fragment>
                    }
                    type="info"
                    showIcon
                  />
                </Col>
              </Row>
            </React.Fragment>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}
const WrappedStripeDetails = Form.create()(StripePayment);
export default WrappedStripeDetails;
