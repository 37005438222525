/**
 * Component for Advanced-Exchanges settings page in the Dashboard.
 *
 * @component
 */

import {
  Alert,
  Button,
  Col,
  Collapse,
  Divider,
  Row,
  Table,
  Tag,
  message
} from 'antd';
import axios from 'axios';
import React from 'react';
import { Link } from 'react-router-dom';
import { IconTray } from '../../../../components/CustomReturnsIcons';
import Upgrade from '../../../../components/Upgrade';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';
import { trackFSEvent } from '../../../../helpers/fullstory';
import AddAdvancedExchange from './components/AddAdvancedExchanges';

const { Panel } = Collapse;

class AdvancedExchanges extends React.Component {
  static contextType = SubscriptionContext;

  constructor(props) {
    super(props);
    this.state = {
      data: [],
      displayAddExchangeSection: false,
      advancedExchangeId: null,
      active: false,
      productName: '',
      showProductTitle: false,
      collections: undefined,
      loading: true,
    };
  }

  async componentDidMount() {
    try {
      await this.fetchAdvancedExchanges();
      this.setState({ loading: false });
    } catch (err) {
      this.setState({ loading: false });
      message.error(
        'Error retrieving advanced exchange settings. Try again.',
        5
      );
    }
  }

  columns = [
    {
      title: 'Matching term in product name',
      key: 'name',
      dataIndex: 'productName',
    },
    {
      title: 'Collections shown for matched product',
      key: 'collection',
      dataIndex: 'collection',
      render: (text, record) => {
        return (
          <div className="flex-row flex-row--noMargin flex-middle-xxs u-rowGap--sm u-columnGap--sm">
            {record.collections.map((collection) => {
              return (
                <Tag
                  color="purple"
                  key={collection.collectionId}
                  style={{ marginBottom: 3 }}
                >
                  {collection.title}
                </Tag>
              );
            })}
          </div>
        );
      },
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'active',
      render: (text) => {
        return <Tag color={text ? 'cyan' : 'red'}>{text ? 'Active' : 'Inactive'}</Tag>;
      },
    },
    {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <div className="flex-row flex-row--noMargin flex-middle-xxs u-rowGap--sm u-columnGap--sm">
          <Button type="ghost" size="small" onClick={() => this.editAdvanceExchange(record)}>Edit</Button>
          <Button type="ghost" size="small" onClick={() => this.deleteAdvanceExchange(record)}>Delete</Button>
        </div>
      ),
    },
  ];

  deleteAdvanceExchange = async (record) => {
    try {
      const response = await axios.delete(
        `/api/advanced-exchanges/${record.id}`
      );
      if (response.status == 200) {
        let newData = this.state.data.filter(
          (advancedExchange) => advancedExchange.id !== record.id
        );
        let deletedRecord = this.state.data.filter(
          (advancedExchange) => advancedExchange.id === record.id
        );
        trackFSEvent('Delete Advanced Exchanged', {
          feature: 'Exchanges',
          numberOfExchangeOptions: newData.length,
          exchangeOptionName:
            deletedRecord.length > 0 ? deletedRecord[0].productName : '',
          exchangeCollectionsForMatchProducts:
            deletedRecord.length > 0
              ? deletedRecord[0].collections.map((i) => i.title).join(',')
              : 0,
        });
        this.setState({ data: newData });
        message.success('Deleted advanced exchange successfully.', 5);
        return;
      }
    } catch (err) {
      message.error('Error deleting advanced exchange. Try again.', 5);
    }
  };

  toggleAddExchangeForm = () => {
    this.setState({
      advancedExchangeId: null,
      active: true,
      showProductTitle: false,
      productName: '',
      collections: [],
      displayAddExchangeSection: !this.state.displayAddExchangeSection,
    });
  };

  async editAdvanceExchange(record) {
    this.setState({
      advancedExchangeId: record.id,
      active: record.active,
      showProductTitle: record.showProductTitle,
      productName: record.productName,
      collections: record.collections,
      displayAddExchangeSection: true,
    });
  }

  async fetchAdvancedExchanges() {
    try {
      const response = await axios.get('/api/advanced-exchanges');
      if (response.data == null) {
        return;
      }
      this.setState({
        data: response.data,
      });
    } catch (err) {
      message.error('Error retrieving your return settings. Try again.', 5);
    }
  }
  async resetEditArguments() {
    this.setState(
      {
        advancedExchangeId: null,
        active: false,
        showProductTitle: false,
        productName: '',
        collections: [],
        displayAddExchangeSection: false,
      },
      () => {
        this.fetchAdvancedExchanges();
      }
    );
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }

    if (this.context.featureAdvancedExchanges === undefined) {
      return (
        <div style={{ position: 'absolute', top: '50%', left: '50%' }}>
          <div className="GlobalLoader GlobalLoader--md"></div>
        </div>
      );
    }
    return (
      <React.Fragment>
        {!this.context.featureAdvancedExchanges ? (
          <React.Fragment>
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Alert
                  message="Already on our Premium Plan?"
                  description={
                    <React.Fragment>
                      <span>
                        Please reach out to us directly in order to have this
                        feature enabled and set up for your account.
                      </span>
                    </React.Fragment>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
            <Upgrade
              videoId="ddcft-4_qas"
              title="Keep more revenue in the business with Advanced-Exchanges."
              description="Upgrade to the Premium Plan to let customers exchange for something similar."
            />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Row
              type="flex"
              justify="space-around"
              align="top"
              className="u-marginBottom--md"
            >
              <Col span={22}>
                <p>
                  Let customers exchange for a similar product (e.g. a different
                  color).
                </p>
                <Alert
                  message="Important"
                  className="u-marginTop--md"
                  description={
                    <React.Fragment>
                      <span>
                        Make sure to enable the 'Advanced Exchange' option in
                        the general{' '}
                        <Link to="/dashboard/settings">
                          Configuration-Returns tab
                        </Link>{' '}
                        or in the Advanced Rules.
                      </span>
                    </React.Fragment>
                  }
                  type="info"
                  showIcon
                />
              </Col>
            </Row>
           
            {/* <Row type="flex" justify="center" style={{ marginTop: 30 }}>
              <Col span={22}>
                <div className="flex-row flex-middle-xxs u-rowGap--sm">
                  <div className="flex-col-xxs-12">
                    <h3 className="u-marginBottom--none">
                      See how this works:
                    </h3>
                  </div>
                  <div className="flex-col-xxs-12">
                    <div className="flex-row flex-row--noMargin u-columnGap--sm flex-middle-xxs">
                      <strong>Launch video-tutorial</strong>{' '}
                      <VideoPopup videoId="qFjyf6YaWfM" />
                    </div>
                  </div>
                </div>
              </Col>
            </Row> */}
            {/* <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row> */}
            <Row
              type="flex"
              justify="space-around"
              align="middle"
              className="u-rowGap--sm u-marginBottom--lg"
            >
              <Col xs={22} sm={22} md={22} lg={22}>
                <h4>Set up Collections</h4>
                <p>
                  Offer new product exchange options based on your Shopify
                  collections. Add or update collections in Shopify as needed.
                </p>
                <a
                  href={
                    'https://' +
                    this.context.myshopify_domain +
                    '/admin/collections'
                  }
                  target="_blank"
                >
                  <Button>
                    {/* <Icon type="edit" /> Go to your Shopify Collections */}
                    Manage Shopify Collections
                  </Button>
                </a>
              </Col>
             
            </Row>
            {/* <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider />
              </Col>
            </Row> */}
            {this.state.displayAddExchangeSection ? (
              <AddAdvancedExchange
                advancedExchangeId={this.state.advancedExchangeId}
                active={this.state.active}
                productName={this.state.productName}
                showProductTitle={this.state.showProductTitle}
                collections={this.state.collections}
                resetEditArguments={this.resetEditArguments.bind(this)}
                toggleAddExchangeForm={this.toggleAddExchangeForm.bind(this)}
                numberOfExchangeOptions={this.state.data.length}
              />
            ) : (
              <React.Fragment>
                <Row type="flex" justify="center" align="top">
                  <Col span={22} style={{overflowX: 'auto'}}>
                    <Table
                      bordered={false}
                      columns={this.columns}
                      dataSource={this.state.data}
                      rowKey={(record) => record.id}
                      locale={{
                        emptyText: (
                          <div className="flex-row flex-middle-xxs flex-center-xxs">
                            <div className="flex-col-xxs-12">
                              <IconTray size="md" />
                            </div>
                            <div>
                              <p className="TextBody TextBody--xxxs">No Data</p>
                            </div>
                          </div>
                        ),
                      }}
                      style={{ minWidth: '768px' }}
                    />
                  </Col>
                </Row>
                <Row
                  type="flex"
                  justify="space-around"
                  align="top"
                  className="u-marginTop--sm u-marginBottom--md"
                >
                  <Col span={22}>
                    <Row type="flex" justify="end" align="top">
                      <Button
                        type="primary"
                        onClick={this.toggleAddExchangeForm}
                      >
                        Add Exchange Option
                      </Button>
                    </Row>
                  </Col>
                </Row>
              </React.Fragment>
            )}
            <Row type="flex" justify="space-around" align="top">
              <Col span={22}>
                <Divider className="u-marginBottom--lg"/>
              </Col>
            </Row>
            <Row type="flex" justify="space-around" align="middle">
              <Col span={22}>
                <div className="flex-row flex-row--noMargin flex-middle-xxs u-columnGap--sm u-marginBottom--md">
                  <div className="element-icon-component element-icon-component--transparent element-icon-component--sm">
                    <svg
                      width="53"
                      height="53"
                      viewBox="0 0 53 53"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M33.1186 24.7453C31.0993 21.8639 31.9005 20.1441 32.0948 18.2219C32.7352 11.9034 36.0168 11.1897 37.6139 12.5094C38.0308 12.8539 36.7182 14.7319 36.8666 16.374C37.1466 19.4604 40.3813 22.7746 37.9433 24.104C35.7112 25.3213 33.7484 25.6437 33.1186 24.7453Z"
                        fill="#5CD3CB"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M46.5224 42.687C43.421 43.2656 35.5894 42.9228 32.0287 42.2444C29.8751 41.8337 28.5536 38.3719 30.1542 38.5618C32.9632 38.8948 44.1701 37.1282 44.0879 34.8059C43.9943 32.1488 47.6354 32.0587 47.6486 37.5566C47.6513 38.8816 48.9259 42.2391 46.5224 42.687Z"
                        fill="#FEEF7C"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.8759 12.6335C20.7707 9.80684 14.189 9.35546 12.0575 9.19381C4.11284 8.59403 5.62069 13.046 6.39802 14.8498C7.07642 16.4265 8.81306 14.6272 10.1195 14.1449C11.759 13.5407 13.5848 13.8852 14.3304 13.9258C16.1403 14.0239 21.0101 16.2331 20.8759 12.6335Z"
                        fill="#5CD3CB"
                      ></path>
                      <path
                        d="M9.38441 11.4486C9.00723 11.4018 8.62828 11.5184 8.34385 11.7702C8.05941 12.0219 7.896 12.3832 7.896 12.763C7.896 17.7645 7.896 33.8067 7.896 38.2843C7.896 38.953 8.3942 39.5165 9.05758 39.5996C13.166 40.111 26.7684 41.8035 26.7684 41.8035C26.7684 41.8035 40.3709 40.111 44.4792 39.5996C45.1426 39.5165 45.6408 38.953 45.6408 38.2843C45.6408 33.8067 45.6408 17.7645 45.6408 12.763C45.6408 12.3832 45.4774 12.0219 45.193 11.7702C44.9085 11.5184 44.5296 11.4018 44.1524 11.4486C39.696 12.0025 26.7684 13.6119 26.7684 13.6119C26.7684 13.6119 13.8408 12.0025 9.38441 11.4486Z"
                        stroke="black"
                        strokeWidth="1.325"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M34.3659 13.0386L34.3633 23.8073L36.9567 22.7287L39.6633 23.8523L39.6624 12.5898"
                        stroke="black"
                        strokeWidth="1.325"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                      <path
                        d="M27.7539 13.9131V40.4131"
                        stroke="black"
                        strokeWidth="1.325"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      ></path>
                    </svg>
                  </div>

                  <div className="TextHeading TextHeading--xxs">
                    Helpful guides
                  </div>
                </div>
              </Col>
              <Col span={22}>
                <Collapse>
                  <Panel header={<strong>Top tips</strong>} key="1">
                    <p>
                      <strong>Good to know:</strong>
                    </p>
                    <p>
                      Advanced Exchanges are always assumed as even exchanges
                      which means that the customer is not automatically
                      charged/refunded for a price difference. If you want to 
                      charge customers for the price difference, check out our{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.richreturns.io/en/articles/9272043-stripe-integration"
                      >
                        Stripe Integration.
                      </a>
                    </p>
                    <p>
                      While you can offer exchanges for cheaper/more expensive 
                      items, you need to manually charge or refund the price 
                      difference unless you have your Stripe Integration set up. 
                      We are checking the live inventory levels for the selected 
                      product on your Shopify-store while the customer chooses 
                      an exchange.
                    </p>
                    <p>
                      Read{' '}
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://support.apphub.com/en/articles/6324979-how-is-inventory-handled-variant-exchanges-one-click-exchange-orders"
                      >
                        here
                      </a>{' '}
                      in our documentation when and how inventory is handled.
                    </p>
                  </Panel>
                  <Panel header={<strong>See how this works</strong>} key="2">
                    <Row type="flex" justify="center" style={{ marginTop: 10 }}>
                      <Col span={24}>
                        <p>
                          Watch this video to understand how exchanges work.
                        </p>
                      </Col>
                    </Row>
                    <Row type="flex" justify="center" style={{ marginTop: 20 }}>
                      <Col span={24}>
                        <div className="flex-row flex-middle-xxs u-rowGap--sm">
                          <div className="flex-col-xxs-12">
                            <iframe
                              width="100%"
                              height="550"
                              frameBorder="0"
                              src={`https://www.youtube.com/embed/qFjyf6YaWfM?showinfo=0&autoplay=0&rel=0`}
                              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              allowFullScreen="allowFullScreen"
                            ></iframe>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Panel>
                </Collapse>
              </Col>
            </Row>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
}

export default AdvancedExchanges;
