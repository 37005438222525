/**
 * Component for user's Subscription with Shopify Billing.
 *
 * @component
 */

import {
  Alert,
  Button,
  Card,
  Checkbox,
  Col,
  Divider,
  Icon,
  Popover,
  Radio,
  Rate,
  Row,
  Slider,
  Tag,
  message
} from 'antd';
import axios from 'axios';
import React, { Component } from 'react';
import PackageLicenses from '../../../../components/PackageLicenses';
import { SubscriptionContext } from '../../../../contexts/SubscriptionContext';

export class Plans extends Component {
  static contextType = SubscriptionContext;
  constructor(props) {
    super(props);
    this.state = {
      featureCustomerPhotoUpload: false,
      featureCustomerPhotoUploadPlus: false,
      featureCustomerPhotoUploadPremium: false,
      featureCustomerPhotoUploadPro: false,
      featureCustomerPhotoUploadStandard: false,

      visible: false,
      plusPlanSelectedTier: undefined,
      durationLimitInIntervals: null,
      discountPercentage: undefined,
      plans: [], // (for new plans flow)
      checkedAddOns: [], // for checked Add On (for new plans flow)
      intervalTime: 'EVERY_30_DAYS',
      yearlyDiscount: undefined,
      monthlyPlusPlanPricing: {
        500: {
          included: 500,
          price: 199,
        },
        750: {
          included: 750,
          price: 299,
        },
        1000: {
          included: 1000,
          price: 399,
        },
        1250: {
          included: 1250,
          price: 499,
        },
        1500: {
          included: 1500,
          price: 599,
        },
        1750: {
          included: 1750,
          price: 699,
        },
        2000: {
          included: 2000,
          price: 799,
        },
        2250: {
          included: 2250,
          price: 899,
        },
        2500: {
          included: 2500,
          price: 999,
        },
      },
      yearlyPlusPlanPricing: {},
      monthlyPlusPlanAddOnPricing: {
        500: {
          included: 500,
          price: 40,
        },
        750: {
          included: 750,
          price: 60,
        },
        1000: {
          included: 1000,
          price: 80,
        },
        1250: {
          included: 1250,
          price: 100,
        },
        1500: {
          included: 1500,
          price: 120,
        },
        1750: {
          included: 1750,
          price: 140,
        },
        2000: {
          included: 2000,
          price: 160,
        },
        2250: {
          included: 2250,
          price: 180,
        },
        2500: {
          included: 2500,
          price: 200,
        },
      },
      yearlyPlusPlanAddOnPricing: {},
    };
  }

  toggleVisibilityOfLicenseModal() {
    this.setState({ visible: !this.state.visible });
  }

  async componentDidMount() {
    await this.getDiscount();
    await this.getPlans(); // call function to fetch plans data
    this.calculateYearlyPlusPlanPricing();
    if (this.context && this.context.featureCustomerPhotoUpload) {
      this.setState({
        featureCustomerPhotoUpload: this.context.featureCustomerPhotoUpload,
      });
    }
  }

  calculateYearlyPlusPlanPricing() {
    const { monthlyPlusPlanPricing, yearlyDiscount, monthlyPlusPlanAddOnPricing } = this.state;
    const yearlyPlusPlanPricing = this.calculatePricing(monthlyPlusPlanPricing, yearlyDiscount);
    const yearlyPlusPlanAddOnPricing = this.calculatePricing(monthlyPlusPlanAddOnPricing, yearlyDiscount);
    this.setState({ 
      yearlyPlusPlanPricing,
      yearlyPlusPlanAddOnPricing
    });
  }

  calculatePricing (pricing, discount) {
    const plans = [500, 750, 1000, 1250, 1500, 1750, 2000, 2250, 2500];
    return plans.reduce((planPricing, value) => {
      const monthlyPrice = pricing[value].price;
      const discountedPrice = Math.floor((monthlyPrice - (monthlyPrice * discount)) * 12);
      planPricing[value] = {
        value,
        price: discountedPrice,
        fakePrice: monthlyPrice * 12,
      };
      return planPricing;
    }, {});
  };

  // Fetch plans relevant data froyearlyPlusPlanAddOnPricing = API
  async getPlans() {
    try {
      const response = await axios.get('/api/plans');
      const plans = response.data.plans;
      this.setState({
        plans,
      });
    } catch (err) {
      console.log('Error getting the plans. Please try again.');
    }
  }

  changeIntervalTime(target) {
    const intervalTime = target.target.value;
    this.setState({
      intervalTime: ['EVERY_30_DAYS', 'ANNUAL'].includes(intervalTime)
        ? intervalTime
        : 'EVERY_30_DAYS',
    });
  }

  async getDiscount() {
    try {
      const response = await axios.get('/api/connections/discountPlan');
      this.setState({
        durationLimitInIntervals: response.data.durationLimitInIntervals,
        discountPercentage: response.data.discountPercentage
          ? response.data.discountPercentage
          : null,
        yearlyDiscount: response.data.yearlyDiscount
          ? response.data.yearlyDiscount
          : 0.2,
      });
    } catch (err) {
      console.log('Error retrieving the billing discount');
    }
  }

  checkAddonPhotoUploadSelected(selectedPlan) {
    if (selectedPlan === 'plus') {
      return this.state.featureCustomerPhotoUploadPlus;
    }
    if (selectedPlan === 'premium') {
      return this.state.featureCustomerPhotoUploadPremium;
    }
    if (selectedPlan === 'pro') {
      return this.state.featureCustomerPhotoUploadPro;
    }
    if (selectedPlan === 'standard') {
      return this.state.featureCustomerPhotoUploadStandard;
    }
    return null;
  }

  async handleSubmit(e) {
    const selectedPlan = e.target.value;
    const selectedAddOn = this.checkAddonPhotoUploadSelected(selectedPlan)
      ? 'addOnCustomerPhotoUpload'
      : null;
    const plusPlanSelectedTier = this.state.plusPlanSelectedTier;
    const intervalTime = this.state.intervalTime;

    // use different endpoint to distinct b/w first time installation flow or update plan subscriton.
    let endpoint = this.props.existingPlan
      ? '/api/shop/shopify/billing/existing'
      : '/api/shop/shopify/billing/planOptions';

    const response = await axios.post(`${endpoint}`, {
      selectedPlan,
      selectedAddOn,
      plusPlanSelectedTier,
      intervalTime: intervalTime,
    });
    if (response.data.status === 'error') {
      return message.error(
        'Error updating your plan. Please try again or contact support (top right corner of your screen).'
      );
    }
    return (window.location.href = response.data.redirectUrl);
  }

  async handleSubmitNewPlanFlow(planId) {
    const addOns = this.state.checkedAddOns
      .filter((checkedAddOn) => checkedAddOn.planId == planId)
      .map((checkedAddOn) => checkedAddOn.addOnId);

    let planSelectedTier;
    this.state.plans.map((plan) => {
      if (plan.id == planId) {
        planSelectedTier = plan.tier;
      }
    });

    try {
      const response = await axios.post(
        '/api/shop/shopify/billing/existing/newPlanFlow',
        {
          planId,
          addOns,
          planSelectedTier,
        }
      );

      if (response.data.status === 'error') {
        return message.error(
          'Error updating your plan. Please try again or contact support (top right corner of your screen).'
        );
      }
      return (window.location.href = response.data.redirectUrl);
    } catch (err) {
      message.error(
        'Error updating your plan. Please try again or contact support.',
        5
      );
    }
  }

  _onChangeAddOn = (planId, addOnId) => {
    let checkedAddOnsCopy = this.state.checkedAddOns;
    const condition = (cond) =>
      cond.planId === planId && cond.addOnId === addOnId;

    const included = checkedAddOnsCopy.some((checkedAddon) =>
      condition(checkedAddon)
    );
    if (included) {
      checkedAddOnsCopy = checkedAddOnsCopy.filter(
        (checkedAddon) => !condition(checkedAddon)
      );
    } else {
      checkedAddOnsCopy.push({
        planId,
        addOnId,
      });
    }
    this.setState({
      checkedAddOns: checkedAddOnsCopy,
    });
  };

  onChangePlanTier = (tier, plan) => {
    const selectedPlan = this.state.plans.map((_plan) => {
      if (_plan.id == plan.id) {
        _plan.price = plan.metadata.slider.steps[tier];
        _plan.tier = tier;
      }
      return _plan;
    });
    this.setState({ plans: [...selectedPlan] });
  };

  onChangeAddon = (e) => {
    const selectedAddOn = e.target.value;
    const addOnEnabled = e.target.checked;

    this.setState(
      {
        [selectedAddOn]: addOnEnabled,
      },
      () => {
        // Callback to update central property based on individual checkbox states
        const featureCustomerPhotoUpload =
          this.state.featureCustomerPhotoUploadPlus ||
          this.state.featureCustomerPhotoUploadPremium ||
          this.state.featureCustomerPhotoUploadPro ||
          this.state.featureCustomerPhotoUploadStandard;
        this.setState({ featureCustomerPhotoUpload });
      }
    );
  };

  onChangePlusPlanTier = (e) => {
    this.setState({
      plusPlanSelectedTier: e,
    });
  };

  planPrice = (subscriptionPrice, fakePrice) => {
    return (
      <React.Fragment>
        {this.state.intervalTime === 'EVERY_30_DAYS' ? (
          this.state.discountPercentage ? (
            <React.Fragment>
              <s>${subscriptionPrice}</s>
              &nbsp; $
              {(
                subscriptionPrice -
                (subscriptionPrice / 100) * this.state.discountPercentage * 100
              ).toFixed(2)}
            </React.Fragment>
          ) : (
            <React.Fragment>${subscriptionPrice.toFixed(2)}</React.Fragment>
          )
        ) : (
          <React.Fragment>
            <s>${fakePrice}</s>
            &nbsp; ${Math[(subscriptionPrice % 1) >= 0.5 ? 'ceil' : 'floor' ](subscriptionPrice).toFixed(2)}
          </React.Fragment>
        )}{' '}
        {this.state.intervalTime === 'ANNUAL' ? (
          <>
            <div className="TextHeading TextHeading--xxxxs">
              per month (billed annually at $
              {(Math[(subscriptionPrice * 12) % 1 >= 0.5 ? 'ceil' : 'floor' ](subscriptionPrice * 12)).toFixed(0)})
            </div>
            <Alert
              message={`Save $${(Math[(fakePrice - subscriptionPrice) * 12 % 1 >= 0.5 ? 'ceil' : 'floor' ]((fakePrice - subscriptionPrice) * 12)).toFixed(
                2
              )} per year!`}
              type="info"
              showIcon
              style={{
                marginTop: 10,
                borderColor: 'var(--rrPrimary)',
              }}
            />
          </>
        ) : (
          'per month'
        )}
      </React.Fragment>
    );
  };

  renderSelectablePlanAddons = (plan) => {
    const selectablePlanAddons = plan.PlanAddOns.filter(
      (planAddOn) => planAddOn.isSelectable
    );
    if (selectablePlanAddons.length === 0) {
      return null;
    }

    return (
      <Col
        style={{
          display: 'flex',
          alignItems: 'flex-start',
        }}
      >
        <Card
          key={`card-plans-${plan.id}`}
          title={`Optional Add-Ons for ${plan.name} Plan`}
          style={{
            width: '100%',
          }}
        >
          <p>
            To add an Add-On to your plan click on enable and then click "Choose
            plan" on your desired plan. Shopify will handle the correct
            pro-rated billing for you.
          </p>
          {selectablePlanAddons.map((planAddOn) => (
            <React.Fragment key={`selectable-${plan.id}-addon-${planAddOn.id}`}>
              <Card
                title={
                  this.context &&
                  this.context[planAddOn.AddOn.featureAddOnName] === true &&
                  plan.name === this.context.planName ? (
                      <Tag
                        color="cyan"
                        style={{
                          position: 'absolute',
                          top: '20px',
                          right: '20px',
                        }}
                      >
                      Active
                      </Tag>
                    ) : null
                }
              >
                <Row>
                  <Col style={{ marginBottom: 10 }}>
                    <Popover
                      content={
                        <React.Fragment>
                          <p key={`PlanAddOn-${planAddOn.AddOn.id}`}>
                            <Icon type="check" /> {planAddOn.AddOn.description}
                          </p>
                          <Divider />
                          {planAddOn.AddOn.metadata &&
                            planAddOn.AddOn.metadata.features &&
                            planAddOn.AddOn.metadata.features.length > 0 &&
                            planAddOn.AddOn.metadata?.features.map(
                              (feature, index) => (
                                <p key={index}>
                                  <Icon type="check" /> {feature}
                                </p>
                              )
                            )}
                          <Divider />
                          <p key={planAddOn.AddOn.id}>
                            <Icon type="check" /> {planAddOn.AddOn.footerNotes}
                          </p>
                        </React.Fragment>
                      }
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        {planAddOn.AddOn.name}
                      </span>
                    </Popover>
                  </Col>
                  <Row
                    type="flex"
                    style={{
                      columnGap: '10px',
                      flexFlow: 'nowrap',
                    }}
                  >
                    <div>
                      <Checkbox
                        onChange={() =>
                          this._onChangeAddOn(plan.id, planAddOn.AddOn.id)
                        }
                        // defaultValue={this.context[planAddOn.AddOn.featureAddOnName] === true && plan.name === this.context.planName}
                      ></Checkbox>
                    </div>
                    <div>
                      {`$ ${
                        planAddOn.price
                          ? planAddOn.price
                          : planAddOn.AddOn.price
                      }`}
                    </div>
                  </Row>
                </Row>
              </Card>
            </React.Fragment>
          ))}
        </Card>
      </Col>
    );
  };

  renderPlanSlider = (plan) => {
    if (
      !plan.metadata &&
      !plan.metadata.slider &&
      !plan.metadata.slider.step &&
      !plan.metadata.slider.min &&
      !plan.metadata.slider.max &&
      !plan.metadata.slider.marks
    ) {
      return;
    }
    return (
      <React.Fragment>
        <p>Returns included per month:</p>
        <Slider
          key={`plan-returns-slider-${plan.id}`}
          min={plan.metadata.slider.min}
          max={plan.metadata.slider.max}
          step={plan.metadata.slider.step}
          marks={plan.metadata.slider.marks}
          tipFormatter={(value) => `${value} returns`}
          onChange={(tier) => this.onChangePlanTier(tier, plan)}
        />
      </React.Fragment>
    );
  };

  renderPlans = (plans) => {
    if (!plans || plans.length === 0) return null;
    const { intervalTime } = this.state;
    const displayPlans = plans.filter(
      (plan) => plan.intervalTime === intervalTime
    );
    return displayPlans.map((plan) => (
      <div
        className="PricingPlans PricingPlans--two"
        style={{
          marginTop: '30px',
        }}
      >
        <Col
          style={{
            display: 'flex',
          }}
          className={`col-plans-${plan.id}`}
        >
          <Card
            style={{
              width: '100%',
            }}
            title={
              <React.Fragment>
                <div className="TextHeading TextHeading--xxs">
                  <span>{plan.name}</span>
                </div>
                <p>
                  <Button
                    type="primary"
                    size="small"
                    onClick={() => this.handleSubmitNewPlanFlow(plan.id)}
                  >
                    Choose plan
                    {/* <Icon type="right" /> */}
                  </Button>
                </p>
              </React.Fragment>
            }
          >
            {this.renderPlanSlider(plan)}
            <h1>
              {plan.tier || this.state.checkedAddOns.length > 0
                ? `$ ${plan.price} + `
                : `$ ${plan.price}`}
            </h1>
            {plan.metadata && plan.metadata.planUsage && (
              <p>${plan.metadata.planUsage} per extra return</p>
            )}
            <Divider />
            {plan.metadata &&
              plan.metadata.features &&
              plan.metadata.features.length > 0 &&
              plan.metadata.features.map((feature, index) => (
                <p key={`plan-${plan.id}-features-${index}`}>
                  <Icon type="check" /> {feature}
                </p>
              ))}

            {plan.PlanAddOns &&
              plan.PlanAddOns.length > 0 &&
              plan.PlanAddOns.map((planAddOn, index) => {
                return !planAddOn.isSelectable ? (
                  <p key={`plan-${plan.id}-addon-features-${index}`}>
                    <Icon type="check" /> {planAddOn.AddOn.name}
                  </p>
                ) : null;
              })}
            <p>
              <Icon type="check" /> {plan.tier ? plan.tier : plan.monthlyQuota}{' '}
              returns included per month
            </p>
          </Card>
        </Col>
        {this.renderSelectablePlanAddons(plan)}
      </div>
    ));
  };

  render() {
    const marks = {
      500: '500',
      750: '',
      1000: '1k',
      1250: '',
      1500: '1.5k',
      1750: '',
      2000: '2k',
      2250: '',
      2500: '2.5k',
    };
    const plusPriceList =
      this.state.intervalTime === 'EVERY_30_DAYS'
        ? this.state.monthlyPlusPlanPricing
        : this.state.yearlyPlusPlanPricing;
    const addOnsPriceList =
      this.state.intervalTime === 'EVERY_30_DAYS'
        ? this.state.monthlyPlusPlanAddOnPricing
        : this.state.yearlyPlusPlanAddOnPricing;
    let percentOfQuota = 0;
    const contextIntervalTime = this.context
      ? this.context.intervalTime
      : 'EVERY_30_DAYS';
    const contextUsageQuota =
      contextIntervalTime === 'EVERY_30_DAYS'
        ? this.context?.monthlyQuota
        : this.context?.monthlyQuota * 12 || 0;

    if (contextUsageQuota === 0) {
      // this is for legacay plans with no monthly quota
      percentOfQuota = 100;
    } else if (this.context?.usageChargeCount === 0) {
      percentOfQuota = 0;
    } else if (this.context?.usageChargeCount > contextUsageQuota) {
      percentOfQuota = 100;
    } else {
      // eslint-disable-next-line no-unused-vars
      percentOfQuota = (
        (this.context?.usageChargeCount / contextUsageQuota) *
        100
      ).toFixed(0);
    }
    return (
      <div>
        <Row
          type="flex"
          justify="center"
          align="top"
          style={{ textAlign: 'center', marginTop: 40, marginBottom: 10 }}
        >
          <Col span={14}>
            <div className="TextHeading TextHeading--xs u-textCenter--all">
              Plans for Any Size of Operation
            </div>
            <div className="TextBody TextBody--xs u-textCenter--all">
              Simple, transparent pricing that grows with you.
            </div>
          </Col>
        </Row>
        <Row
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: 40,
            marginTop: 0,
          }}
        >
          <Radio.Group
            value={this.state.intervalTime}
            onChange={this.changeIntervalTime.bind(this)}
          >
            <Radio.Button
              value="EVERY_30_DAYS"
              style={{
                width: '140px',
                display: 'inline-grid',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Monthly
            </Radio.Button>
            <Radio.Button value="ANNUAL">Yearly ({this.state.yearlyDiscount * 100}% off)</Radio.Button>
          </Radio.Group>
        </Row>
        <div className="PricingPlans PricingPlans--three">
          <Col
            style={{
              display: 'flex',
            }}
          >
            <Card
              style={{
                width: '100%',
              }}
              title={
                <React.Fragment>
                  <svg
                    width="53"
                    height="53"
                    viewBox="0 0 53 53"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_3075_16853)" fill="none">
                      <path
                        d="M29.832 39.1211C30.2284 40.954 37.8316 39.5738 39.8317 38.6204C43.2683 36.9821 45.6589 28.6206 41.832 28.6206C38.8317 28.6206 38.8319 33.1209 36.3324 34.6211C33 36.6211 28.8316 34.4944 29.832 39.1211Z"
                        fill="#C8E5FF"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.38425 16.0118C10.2602 15.0408 13.7449 12.0609 20.1141 12.1525C23.3595 12.1992 25.2041 13.5533 27.603 13.2512C29.9522 12.9554 29.5346 16.4569 25.5186 16.3251C20.158 16.1492 19.3725 16.7674 17.8447 17.4219C13.3401 19.3515 6.65582 19.0365 9.38425 16.0118Z"
                        fill="#9CFEFE"
                      ></path>
                      <path
                        d="M30.537 35.0723V18.9238H37.9383C38.988 19.0099 39.9624 19.5027 40.6538 20.2972C41.3452 21.0916 41.6987 22.1248 41.639 23.1762V33.6593C41.6576 34.0097 41.5386 34.3536 41.3074 34.6176C41.0761 34.8815 40.7508 35.0447 40.401 35.0723H38.2748"
                        stroke="#000000"
                        strokeWidth="1.32px"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                      ></path>
                      <path
                        d="M38.2744 35.0728C38.2744 35.4704 38.1961 35.8641 38.0439 36.2315C37.8918 36.5988 37.6687 36.9326 37.3876 37.2138C37.1064 37.4949 36.7726 37.7179 36.4053 37.8701C36.0379 38.0223 35.6442 38.1006 35.2466 38.1006C34.849 38.1006 34.4552 38.0223 34.0879 37.8701C33.7205 37.7179 33.3867 37.4949 33.1056 37.2138C32.8244 36.9326 32.6014 36.5988 32.4492 36.2315C32.2971 35.8641 32.2187 35.4704 32.2188 35.0728C32.2187 34.6751 32.2971 34.2814 32.4492 33.9141C32.6014 33.5467 32.8244 33.2129 33.1056 32.9318C33.3867 32.6506 33.7205 32.4276 34.0879 32.2754C34.4552 32.1232 34.849 32.0449 35.2466 32.0449C35.6442 32.0449 36.0379 32.1232 36.4053 32.2754C36.7726 32.4276 37.1064 32.6506 37.3876 32.9318C37.6687 33.2129 37.8918 33.5467 38.0439 33.9141C38.1961 34.2814 38.2744 34.6751 38.2744 35.0728Z"
                        stroke="#000000"
                        strokeWidth="1.32px"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                      ></path>
                      <path
                        d="M22.4629 35.0728C22.4629 35.8758 22.1439 36.6459 21.5761 37.2138C21.0082 37.7816 20.2381 38.1006 19.4351 38.1006C18.632 38.1006 17.8619 37.7816 17.2941 37.2138C16.7262 36.6459 16.4072 35.8758 16.4072 35.0728C16.4072 34.2697 16.7262 33.4996 17.2941 32.9318C17.8619 32.3639 18.632 32.0449 19.4351 32.0449C20.2381 32.0449 21.0082 32.3639 21.5761 32.9318C22.1439 33.4996 22.4629 34.2697 22.4629 35.0728Z"
                        stroke="#000000"
                        strokeWidth="1.32px"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                      ></path>
                      <path
                        d="M32.2188 35.0723H22.4624"
                        stroke="#000000"
                        strokeWidth="1.32px"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                      ></path>
                      <path
                        d="M11.3608 28.344H30.5371V16.9189C30.5371 16.3836 30.3244 15.8702 29.9459 15.4916C29.5673 15.1131 29.0539 14.9004 28.5186 14.9004H13.3794C12.844 14.9004 12.3306 15.1131 11.9521 15.4916C11.5735 15.8702 11.3608 16.3836 11.3608 16.9189V28.344Z"
                        stroke="#000000"
                        strokeWidth="1.32px"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                      ></path>
                      <path
                        d="M16.4072 35.0723H13.3794C12.844 35.0723 12.3306 34.8596 11.9521 34.481C11.5735 34.1025 11.3608 33.5891 11.3608 33.0537V28.3438"
                        stroke="#000000"
                        strokeWidth="1.32px"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_3075_16853">
                        <rect
                          width="53"
                          height="53"
                          fill="white"
                          transform="matrix(-1 0 0 1 53 0)"
                        ></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="TextHeading TextHeading--sm u-textCenter--all">
                    Plus Plan
                    {this.context && this.context.planName === 'plus' && (
                      <Tag
                        color="cyan"
                        style={{
                          position: 'absolute',
                          top: '20px',
                          right: '20px',
                        }}
                      >
                        Active
                      </Tag>
                    )}
                  </div>
                  <div className="TextBody TextBody--xs u-textCenter--all">
                    Advanced returns and exchange management for established
                    merchants.
                  </div>

                  <Button
                    type="primary"
                    onClick={this.handleSubmit.bind(this)}
                    value="plus"
                  >
                    {this.state.discountPercentage
                      ? 'Choose Discount Plan'
                      : 'Choose plan'}
                    {/* <Icon type="right" /> */}
                  </Button>
                </React.Fragment>
              }
            >
              <p>Returns included per month:</p>
              <Slider
                defaultValue={
                  this.state.plusPlanSelectedTier
                    ? this.state.plusPlanSelectedTier
                    : 500
                }
                min={500}
                max={2500}
                step={250}
                marks={marks}
                tipFormatter={(value) => `${value} returns`}
                onChange={this.onChangePlusPlanTier.bind(this)}
              />
              <h1>
                {this.state.plusPlanSelectedTier
                  ? this.planPrice(
                    this.state.intervalTime === 'EVERY_30_DAYS'
                      ? plusPriceList[this.state.plusPlanSelectedTier].price
                      : plusPriceList[this.state.plusPlanSelectedTier].price /
                            12,
                    this.state.intervalTime === 'EVERY_30_DAYS'
                      ? plusPriceList[this.state.plusPlanSelectedTier]
                        .fakePrice
                      : plusPriceList[this.state.plusPlanSelectedTier]
                        .fakePrice / 12
                  )
                  : this.state.intervalTime === 'EVERY_30_DAYS'
                    ? this.planPrice(199)
                    : this.planPrice(plusPriceList['500'].price /12, plusPriceList['500'].fakePrice / 12)}
              </h1>
              <p>$0.5 per extra return</p>

              <Divider />

              <p>
                <Icon type="check" />{' '}
                {this.state.plusPlanSelectedTier
                  ? plusPriceList[this.state.plusPlanSelectedTier].included
                  : 500}
                + monthly returns (larger plans available)
              </p>
              <p>
                <Icon type="check" /> All PREMIUM Features
              </p>
              <p>
                <Icon type="check" /> Exchange for a different item
              </p>
              <p>
                <Icon type="check" /> Store-Wide Exchange
              </p>
              <p>
                <Icon type="check" /> Stripe Payments
              </p>
              <p>
                <Icon type="check" /> Custom Domain
              </p>
              <p>
                <Icon type="check" /> International Labels
              </p>
              <p>
                <Icon type="check" /> Audit Log
              </p>
              <p>
                <Icon type="check" /> Auto Tracking
              </p>
              <p>
                <Icon type="check" /> API Access
              </p>
              <p>
                <Icon type="check" /> QR-Code Labels
              </p>
              <p>
                <Icon type="check" /> Advanced Rules (20 included)
              </p>
            </Card>
          </Col>

          <Col
            style={{
              display: 'flex',
            }}
          >
            <Card
              style={{
                width: '100%',
              }}
              title={
                <React.Fragment>
                  <svg
                    width="53"
                    height="53"
                    viewBox="0 0 53 53"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M22.6904 36.1112C29.9875 34.695 31.2609 34.1414 32.8411 33.0337C36.2121 30.6705 40.7253 31.0129 40.1949 33.4242C39.1992 37.9513 35.6445 34.392 26.1331 41.1275C21.5708 44.3583 18.6431 36.8966 22.6904 36.1112Z"
                      fill="#C8E5FF"
                    ></path>
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M11.0425 20.9803C9.48937 20.6699 8.19144 19.0208 9.9383 17.6678C11.0425 17.0335 13.8029 16.0116 14.4499 17.0335C16.0112 19.3241 21.1431 24.3387 18.3323 26.9711C15.4591 28.7095 16.3236 25.3972 15.4594 24.2931C14.8127 23.1891 14.3552 21.5326 11.0425 20.9803Z"
                      fill="#9CFEFE"
                    ></path>
                    <path
                      d="M26.8818 37.2988L40.892 30.7658"
                      stroke="#000000"
                      strokeWidth="1.32px"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                    ></path>
                    <path
                      d="M10.7266 18.9805L14.8064 19.5713L22.3421 35.7318"
                      stroke="#000000"
                      strokeWidth="1.32px"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                    ></path>
                    <path
                      d="M23.5858 27.1124L20.3193 20.1074L28.3251 16.3743L31.5915 23.3793"
                      stroke="#000000"
                      strokeWidth="1.32px"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                    ></path>
                    <path
                      d="M27.9721 36.5186L37.7291 31.9689L33.3888 22.6612L23.6318 27.2109L27.9721 36.5186Z"
                      stroke="#000000"
                      strokeWidth="1.32px"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                    ></path>
                    <path
                      d="M21.2936 39.7537C21.603 40.4173 22.1633 40.9307 22.8512 41.1811C23.5392 41.4315 24.2984 41.3983 24.9619 41.0889C25.6255 40.7795 26.1389 40.2192 26.3893 39.5313C26.6397 38.8433 26.6065 38.0841 26.2971 37.4205C25.9877 36.757 25.4274 36.2436 24.7395 35.9932C24.0515 35.7428 23.2923 35.7759 22.6287 36.0854C21.9652 36.3948 21.4518 36.9551 21.2014 37.643C20.951 38.331 20.9842 39.0902 21.2936 39.7537Z"
                      stroke="#000000"
                      strokeWidth="1.32px"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      fill="none"
                    ></path>
                  </svg>
                  <div className="TextHeading TextHeading--sm u-textCenter--all">
                    Premium Plan
                    {this.context &&
                      this.context.planName &&
                      this.context.planName === 'premium' && (
                      <Tag
                        color="cyan"
                        style={{
                          position: 'absolute',
                          top: '20px',
                          right: '20px',
                        }}
                      >
                          Active
                      </Tag>
                    )}
                  </div>

                  <div className="TextBody TextBody--xs u-textCenter--all">
                    Ideal for merchants who are beginning to scale their
                    business.
                  </div>
                  <Button
                    type="primary"
                    // size="small"
                    onClick={this.handleSubmit.bind(this)}
                    value="premium"
                  >
                    {this.state.discountPercentage
                      ? 'Choose Discount Plan'
                      : 'Choose plan'}
                    {/* <Icon type="right" /> */}
                  </Button>
                </React.Fragment>
              }
            >
              <h1>
                {this.state.intervalTime === 'EVERY_30_DAYS'
                  ? this.planPrice(99)
                  : this.planPrice((99 - (99 * this.state.yearlyDiscount)), 99)}
              </h1>
              <p>$0.5 per extra return</p>
              <Divider />
              <p>
                <Icon type="check" /> 100 monthly returns ($0.5 per extra
                return)
              </p>
              <p>
                <Icon type="check" /> All PRO Features
              </p>
              <p>
                <Icon type="check" /> Automated Refund
              </p>
              <p>
                <Icon type="check" /> Advance Exchanges
              </p>
              <p>
                <Icon type="check" /> Advanced Rules (10 included)
              </p>
              <p>
                <Icon type="check" /> Advanced Reports & Trends
              </p>
              <p>
                <Icon type="check" /> Automatic Exchanges / Exchange Orders
              </p>
              <p>
                <Icon type="check" /> Customer Incentives
              </p>
            </Card>
          </Col>

          <Col
            style={{
              display: 'flex',
            }}
          >
            <Card
              style={{
                width: '100%',
              }}
              title={
                <React.Fragment>
                  <svg
                    width="53"
                    height="53"
                    viewBox="0 0 53 53"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_3073_16542)" fill="none">
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M36.6646 34.6006C38.3209 34.0485 39.4251 32.3923 39.9772 33.4964C39.9897 35.2403 40.5857 39.2878 38.3209 39.0173C26.7122 37.6301 21.472 40.3601 21.0363 37.9955C20.0187 32.4703 32.957 34.827 36.6646 34.6006Z"
                        fill="#C8E5FF"
                      ></path>
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M8.83343 20.4267C5.48637 21.2654 6.86743 27.9956 16.7019 27.3535C19.3231 24.8434 18.771 21.5309 20.9793 19.8746C22.6356 18.6324 23.7397 15.2214 21.5314 14.9059C17.6668 14.3538 18.3289 14.8058 15.4585 14.9059C12.2276 15.0182 11.992 19.6353 8.83343 20.4267Z"
                        fill="#9CFEFE"
                      ></path>
                      <path
                        d="M27.9345 36.547H18.6043C18.0333 36.547 16.4511 36.547 16.4511 36.547C16.4511 36.547 16.4512 34.9649 16.4512 34.3939V16.4512"
                        stroke="#000000"
                        strokeWidth="1.32px"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                      ></path>
                      <path
                        d="M38.7002 25.4375V34.3945C38.7002 34.9655 38.7002 36.5476 38.7002 36.5476C38.7002 36.5476 37.1181 36.5476 36.5471 36.5476H27.9346"
                        stroke="#000000"
                        strokeWidth="1.32px"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                      ></path>
                      <path
                        d="M27.4756 16.4512L30.2316 24.7766C30.3119 24.973 30.4491 25.1408 30.6257 25.2585C30.8022 25.3762 31.0099 25.4383 31.2221 25.4369H41.8585C41.988 25.4358 42.1153 25.404 42.2301 25.3441C42.3449 25.2842 42.4438 25.198 42.5188 25.0924C42.5905 24.9832 42.6345 24.8581 42.647 24.7281C42.6595 24.598 42.6402 24.4668 42.5906 24.346L39.9063 16.4512H15.0162L10.4659 24.2742C10.395 24.391 10.3574 24.5251 10.3574 24.6618C10.3574 24.7984 10.395 24.9325 10.4659 25.0493C10.5366 25.1688 10.6375 25.2675 10.7584 25.3355C10.8794 25.4036 11.0161 25.4385 11.1549 25.4369H16.4516"
                        stroke="#000000"
                        strokeWidth="1.32px"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        fill="none"
                      ></path>
                    </g>
                    <defs>
                      <clipPath id="clip0_3073_16542">
                        <rect width="53" height="53" fill="white"></rect>
                      </clipPath>
                    </defs>
                  </svg>
                  <div className="TextHeading TextHeading--sm u-textCenter--all">
                    Pro Plan
                    {this.context && this.context.planName === 'pro' && (
                      <Tag
                        color="cyan"
                        style={{
                          position: 'absolute',
                          top: '20px',
                          right: '20px',
                        }}
                      >
                        Active
                      </Tag>
                    )}
                  </div>

                  <div className="TextBody TextBody--xs u-textCenter--all">
                    Ideal for merchants looking to streamline their returns
                    process with automation.
                  </div>

                  <Button
                    type="primary"
                    onClick={this.handleSubmit.bind(this)}
                    value="pro"
                  >
                    {this.state.discountPercentage
                      ? 'Choose Discount Plan'
                      : 'Choose plan'}
                  </Button>
                </React.Fragment>
              }
            >
              <h1>
                {this.state.intervalTime === 'EVERY_30_DAYS'
                  ? this.planPrice(39)
                  : this.planPrice((39 - (39 * this.state.yearlyDiscount)), 39)}
              </h1>
              <p>$0.75 per extra return</p>
              <Divider />
              <p>
                <Icon type="check" /> 50 monthly returns ($0.75 include per
                extra return)
              </p>
              <p>
                <Icon type="check" /> All STANDARD Features
              </p>
              <p>
                <Icon type="check" /> Variant Exchanges
              </p>
              <p>
                <Icon type="check" /> Advanced Analytics Dashboard
              </p>
              <p>
                <Icon type="check" /> Multi-Language Return Portal
              </p>
              <p>
                <Icon type="check" /> Language Customization
              </p>
              {this.state.returnReasonLanguage && (
                <p>
                  <Icon type="check" /> Multi-Language Return Reasons
                </p>
              )}
              <p>
                <Icon type="check" /> CSV Export
              </p>
              <p>
                <Icon type="check" /> Update Return Resolution
              </p>
            </Card>
          </Col>
        </div>

        <div
          className="PricingPlans PricingPlans--three"
          style={{
            marginTop: 30,
          }}
        >
          <Col
            style={{
              display: 'flex',
            }}
          >
            <Card
              title="Add-Ons for Plus Plan"
              style={{
                width: '100%',
              }}
            >
              <p>
                To add an Add-On to your plan click on enable and then click
                "Choose plan" on your desired plan. Shopify will handle the
                correct pro-rated billing for you.
              </p>
              <Card
                title={
                  this.context &&
                  this.context.featureCustomerPhotoUpload &&
                  this.context.planName === 'plus' && (
                    <Tag
                      color="cyan"
                      style={{
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                      }}
                    >
                      Active
                    </Tag>
                  )
                }
              >
                <Row>
                  <Col style={{ marginBottom: 10 }}>
                    <Popover
                      content={
                        <React.Fragment>
                          <p>
                            Let customers upload photos of their returned items.
                            Make more educated decisions regarding returns.
                          </p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Review photos before approving
                            the return
                          </p>
                          <p>
                            <Icon type="check" /> Option to make uploading
                            photos a requirement
                          </p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Price includes photo uploads
                            for your monthly quota of{' '}
                            {this.state.plusPlanSelectedTier
                              ? this.state.monthlyPlusPlanAddOnPricing[
                                this.state.plusPlanSelectedTier
                              ].included
                              : 500}{' '}
                            returns. Adds $0.25 per extra return (in addition to
                            regular overage charges).
                          </p>
                        </React.Fragment>
                      }
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        Customer photo uploads
                      </span>
                    </Popover>
                  </Col>
                  <Row
                    type="flex"
                    style={{
                      columnGap: '10px',
                      flexFlow: 'nowrap',
                    }}
                  >
                    <div>
                      <Checkbox
                        onChange={this.onChangeAddon}
                        checked={this.state.featureCustomerPhotoUploadPlus}
                        value="featureCustomerPhotoUploadPlus"
                      ></Checkbox>
                    </div>
                    <div
                      style={{
                        width: '100%',
                      }}
                    >
                      Enable{' '}
                      {this.planPrice(
                        this.state.intervalTime === 'EVERY_30_DAYS'
                          ? addOnsPriceList[
                            this.state.plusPlanSelectedTier
                              ? this.state.plusPlanSelectedTier
                              : 500
                          ].price
                          : addOnsPriceList[
                            this.state.plusPlanSelectedTier
                              ? this.state.plusPlanSelectedTier
                              : 500
                          ].price / 12,
                        this.state.intervalTime === 'EVERY_30_DAYS'
                          ? addOnsPriceList[
                            this.state.plusPlanSelectedTier
                              ? this.state.plusPlanSelectedTier
                              : 500
                          ].fakePrice
                          : addOnsPriceList[
                            this.state.plusPlanSelectedTier
                              ? this.state.plusPlanSelectedTier
                              : 500
                          ].fakePrice / 12
                      )}
                    </div>
                  </Row>
                </Row>
              </Card>
            </Card>
          </Col>

          <Col
            style={{
              display: 'flex',
            }}
          >
            <Card
              title="Add-Ons for Premium Plan"
              style={{
                width: '100%',
              }}
            >
              <p>
                To add an Add-On to your plan click on enable and then click
                "Choose plan" on your desired plan. Shopify will handle the
                correct pro-rated billing for you.
              </p>
              <Card
                title={
                  this.context &&
                  this.context.featureCustomerPhotoUpload &&
                  this.context.planName === 'premium' && (
                    <Tag
                      color="cyan"
                      style={{
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                      }}
                    >
                      Active
                    </Tag>
                  )
                }
              >
                <Row>
                  <Col style={{ marginBottom: 10 }}>
                    <Popover
                      content={
                        <React.Fragment>
                          <p>
                            Let customers upload photos of their returned items.
                            Make more educated decisions regarding returns.
                          </p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Review photos before approving
                            the return
                          </p>
                          <p>
                            <Icon type="check" /> Option to make uploading
                            photos a requirement
                          </p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Price includes photo uploads
                            for your monthly quota of 100 returns. Adds $0.25
                            per extra return (in addition to regular overage
                            charges).
                          </p>
                        </React.Fragment>
                      }
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        Customer photo uploads
                      </span>
                    </Popover>
                  </Col>
                  <Row
                    type="flex"
                    style={{
                      columnGap: '10px',
                      flexFlow: 'nowrap',
                    }}
                  >
                    <div>
                      <Checkbox
                        onChange={this.onChangeAddon}
                        checked={this.state.featureCustomerPhotoUploadPremium}
                        value="featureCustomerPhotoUploadPremium"
                      ></Checkbox>
                    </div>
                    <div
                      style={{
                        width: '100%',
                      }}
                    >
                      Enable{' '}
                      {this.state.intervalTime === 'EVERY_30_DAYS'
                        ? this.planPrice(20)
                        : this.planPrice((20 - (20 * this.state.yearlyDiscount)), 20)}
                    </div>
                  </Row>
                </Row>
              </Card>
            </Card>
          </Col>

          <Col
            style={{
              display: 'flex',
            }}
          >
            <Card
              title="Add-Ons for Pro Plan"
              style={{
                width: '100%',
              }}
            >
              <p>
                To add an Add-On to your plan click on enable and then click
                "Choose plan" on your desired plan. Shopify will handle the
                correct pro-rated billing for you.
              </p>
              <Card
                title={
                  this.context &&
                  this.context.featureCustomerPhotoUpload &&
                  this.context.planName === 'pro' ? (
                      <Tag
                        color="cyan"
                        style={{
                          position: 'absolute',
                          top: '20px',
                          right: '20px',
                        }}
                      >
                      Active
                      </Tag>
                    ) : null
                }
              >
                <Row>
                  <Col style={{ marginBottom: 10 }}>
                    <Popover
                      content={
                        <React.Fragment>
                          <p>
                            Let customers upload photos of their returned items.
                            Make more educated decisions regarding returns.
                          </p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Review photos before approving
                            the return
                          </p>
                          <p>
                            <Icon type="check" /> Option to make uploading
                            photos a requirement
                          </p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Price includes photo uploads
                            for your monthly quota of 50 returns. Adds $0.25 per
                            extra return (in addition to regular overage
                            charges).
                          </p>
                        </React.Fragment>
                      }
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        Customer photo uploads
                      </span>
                    </Popover>
                  </Col>
                  <Row
                    type="flex"
                    style={{
                      columnGap: '10px',
                      flexFlow: 'nowrap',
                    }}
                  >
                    <div>
                      <Checkbox
                        onChange={this.onChangeAddon}
                        checked={this.state.featureCustomerPhotoUploadPro}
                        value="featureCustomerPhotoUploadPro"
                      ></Checkbox>
                    </div>
                    <div>
                      {this.state.intervalTime === 'EVERY_30_DAYS'
                        ? this.planPrice(10)
                        : this.planPrice((10 - (10 * this.state.yearlyDiscount)), 10)}
                    </div>
                  </Row>
                </Row>
              </Card>
            </Card>
          </Col>
        </div>

        <div
          style={{
            marginTop: '60px',
            marginBottom: '30px',
            alignItems: 'center',
          }}
        >
          <Col
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <div style={{ filter: 'grayscale(1)', textAlign: 'center' }}>
              <h1 className="u-PageHeader">
                Securely checkout with your Shopify account
              </h1>
              <div>
                <img src="/Shopify.png" style={{ maxHeight: 30 }} />
              </div>
              <Rate disabled defaultValue={5} />
            </div>
          </Col>
        </div>

        <div
          className="PricingPlans PricingPlans--two"
          style={{
            marginTop: '30px',
          }}
        >
          <Col
            style={{
              display: 'flex',
            }}
          >
            <Card
              style={{
                width: '100%',
              }}
              title={
                <React.Fragment>
                  <div className="TextHeading TextHeading--xxs">
                    Standard Plan{' '}  
                    {this.context && this.context.planName === 'standard' && (
                      <Tag
                        color="cyan"
                        style={{
                          position: 'absolute',
                          top: '20px',
                          right: '20px',
                        }}
                      >
                        Active
                      </Tag>
                    )}
                  </div>

                  <p>
                    <Button
                      type="primary"
                      size="small"
                      onClick={this.handleSubmit.bind(this)}
                      value="standard"
                    >
                      {this.state.discountPercentage
                        ? 'Choose Discount Plan'
                        : 'Choose plan'}
                      {/* <Icon type="right" /> */}
                    </Button>
                  </p>
                </React.Fragment>
              }
            >
              <div className="TextHeading TextHeading--xxs">
                {this.state.intervalTime === 'EVERY_30_DAYS'
                  ? this.planPrice(19)
                  : this.planPrice((19 - (19 * this.state.yearlyDiscount)), 19)}
              </div>
              <div className="TextBody TextBody--xxs">$1.00 per extra return</div>
              <Divider />
              <p>
                <Icon type="check" /> 10 monthly returns ($1 per extra return)
              </p>
              <p>
                <Icon type="check" /> Branded Returns Portal
              </p>
              <p>
                <Icon type="check" /> Automated Prepaid Labels
              </p>
              <p>
                <Icon type="check" /> Auto-Return Approval
              </p>
              <p>
                <Icon type="check" /> Multi-Language Customer Notifications
              </p>
              <p>
                <Icon type="check" /> Supported integrations: Klaviyo, Gorgias
                and Intercom
              </p>
            </Card>
          </Col>

          <Col
            style={{
              display: 'flex',
              alignItems: 'flex-start',
            }}
          >
            <Card
              style={{
                width: '100%',
              }}
              title="Optional Add-Ons for Standard Plan"
            >
              <p style={{ marginBottom: 40 }}>
                To add an Add-On to your plan click on enable and then click
                "Choose plan" on your desired plan. Shopify will handle the
                correct pro-rated billing for you.
              </p>
              <Card
                title={
                  this.context &&
                  this.context.featureCustomerPhotoUpload &&
                  this.context.planName === 'pro' && (
                    <Tag
                      color="cyan"
                      style={{
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                      }}
                    >
                      Active
                    </Tag>
                  )
                }
              >
                <Row>
                  <Col style={{ marginBottom: 10 }}>
                    <Popover
                      content={
                        <React.Fragment>
                          <p>
                            Let customers upload photos of their returned items.
                            Make more educated decisions regarding returns.
                          </p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Review photos before approving
                            the return
                          </p>
                          <p>
                            <Icon type="check" /> Option to make uploading
                            photos a requirement
                          </p>
                          <p>
                            <Icon type="check" /> Add a custom description for
                            best photo results
                          </p>
                          <p>
                            <Icon type="check" /> Storage of photos included
                          </p>
                          <p>
                            <Icon type="check" /> Backup of photos included
                          </p>
                          <p>
                            <Icon type="check" /> Lifelong archiving of photos
                            included
                          </p>
                          <Divider />
                          <p>
                            <Icon type="check" /> Price includes photo uploads
                            for your monthly quota of 10 returns. Adds $0.25 per
                            extra return (in addition to regular overage
                            charges).
                          </p>
                        </React.Fragment>
                      }
                    >
                      <span style={{ fontWeight: 'bold' }}>
                        Customer photo uploads
                      </span>
                    </Popover>
                  </Col>
                  <Row
                    type="flex"
                    style={{
                      columnGap: '10px',
                      flexFlow: 'nowrap',
                    }}
                  >
                    <div>
                      <Checkbox
                        onChange={this.onChangeAddon}
                        checked={this.state.featureCustomerPhotoUploadStandard}
                        value="featureCustomerPhotoUploadStandard"
                      ></Checkbox>
                    </div>
                    <div
                      style={{
                        width: '100%',
                      }}
                    >
                      Enable{' '}
                      {this.state.intervalTime === 'EVERY_30_DAYS'
                        ? this.planPrice(5)
                        : this.planPrice((5 - (5 * this.state.yearlyDiscount)), 5)}
                    </div>
                  </Row>
                </Row>
              </Card>
            </Card>
          </Col>
        </div>
        {this.renderPlans(this.state.plans)}

        <Divider />

        <div className="TextBody TextBody--xs u-textCenter--all u-marginTop--lg u-marginBottom--md">
          Please see the links below to view our Terms & Conditions, Privacy
          Policy, and other important legal documents:
        </div>

        <Row
          type="flex"
          style={{
            justifyContent: 'center',
            columnGap: '15px',
            marginBottom: '30px',
          }}
        >
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.richreturns.io/terms-conditions/"
          >
            Terms
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.richreturns.io/terms-conditions/"
          >
            Privacy
          </a>
          <Button
            style={{ color: '#67C2C2', padding: 'unset', boxShadow: 'none' }}
            type="link"
            onClick={() => this.toggleVisibilityOfLicenseModal()}
          >
            Licenses
          </Button>
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.richreturns.io/legal-imprint"
          >
            Imprint
          </a>
        </Row>
        <PackageLicenses
          visible={this.state.visible}
          onCancel={() => this.toggleVisibilityOfLicenseModal()}
        />
      </div>
    );
  }
}

export default Plans;
