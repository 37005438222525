/**
 * Component for the product-selection page of our Return Portals
 * where customers choose the products they want to return
 * and also give details like Return Reasons.
 * 
 * @component
 */

import { Badge, Button, Card, Checkbox, Col, Divider, Form, Icon, Input, List, Popover, Radio, Row, Select, Statistic, Switch, Tooltip, message } from "antd";
import axios from "axios";
import React from "react";
import { FormattedMessage, defineMessages, injectIntl } from "react-intl";
import { Currencies } from "../../constants/constants";
import { getLocale } from "../../helpers/localePortal";
import '../../index.css';
import ExchangeOptions from "./advancedExchanges/ExchangeOptions";
import StoreWideProducts from "./storeWideExchanges/StoreWideProducts";

const { TextArea } = Input;
const FormItem = Form.Item;
const Option = Select.Option;

const innerWidthOfWindow = window.innerWidth;
const isMobile = innerWidthOfWindow && innerWidthOfWindow < 576 ? true : false;
const { Countdown } = Statistic;

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 24 },
  },
  labelAlign: 'left'
};

class Order extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      availableResolutionOptions: [
        "storeCredit",
        "exchangeItem",
        "storeWideExchange",
        "originalPaymentMethod",
        "advancedExchange",
        "refundToCustom1",
        "refundToCustom2",
        "refundToCustom3",
      ],
      resolutionOptionSelected: this.state
        ? this.state.availableResolutionOptions[0]
        : "storeCredit",
      advancedExchangeOptions: {},
      submitButtonDisabled: false,
      loadingExchangeOptions: false,
      exchangeOptionsTitle: undefined,
      exchangeOptionsVisible: false,
      selectedAdvancedExchangeOptions: [],
      advancedExchangeLineItemId: null,
      advancedExchanges: {},
      exchangeSameVariant: true,
      returnReasons: [],
      showProducts:false,
      currentPoduct:{},
      customerLocale: getLocale(),
      shouldHideStoreWideExchangesModal: true,
      expiredItems: [],
      additionalFields: [],
      extraNote: {},
      advancedRuleExtraNotesSetting: {},
      advancedRuleExtraNotes: {},
      labelFee: 0,
      itemQuantity: {},
    };
  }

  async componentDidMount() {
    // scroll to top of page
    window.scrollTo(0, 0);
    
    this.handlegetReturnReasons()
    // message object from Antd
    // used to remove potentially remaining customer-facing messages as part of the previous component
    message.destroy();
    this.handleBack = this.handleBack.bind(this);
    window.addEventListener('popstate', this.handleBack);
    try {
      const responseWithResolutionOptions = await axios.post("/api/visitors/return/resolutions", {
        companyIdentifier: this.props.companyIdentifier,
        returnNumber: this.props.returnNumber,
      });
      const incentives = responseWithResolutionOptions.data.incentives;
      const dictatingResolutions = incentives?.filter(incentive => {
        return incentive.dictatIncentive === true
      }).map(item => item.dictatResolution)

      let itemQuantity;
      this.props?.orderData?.line_items?.map((product)=> {
        itemQuantity = {
          ...itemQuantity,
          [`quantity_${product.variant_id}`]: 1,
        }
      });
      return this.setState({
        showIncentives: responseWithResolutionOptions.data.showIncentives,
        incentivesResolutions: responseWithResolutionOptions.data.incentivesResolutions,
        incentives,
        dictatingResolutions,
        availableResolutionOptions: responseWithResolutionOptions.data.availableResolutionOptions,
        resolutionOptionSelected: responseWithResolutionOptions.data.availableResolutionOptions[0],
        advancedExchangeOptions: responseWithResolutionOptions.data.advancedExchangeOptions,
        exchangeSameVariant: responseWithResolutionOptions.data.returnSettings.exchangeSameVariant,
        customerLocale: responseWithResolutionOptions.data.customerLocale ? responseWithResolutionOptions.data.customerLocale : 'en',
        additionalFields: responseWithResolutionOptions.data.returnSettings.additionalFields || [],
        itemQuantity,
      });
    } catch(err) {
      message.error("Error while loading your resolution options. Please try again.", 4);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('popstate', this.handleBack);
  }

  handleCheckboxChange(productLineItemId, { title, option, checked, target }) {
    const extraNote = this.state.extraNote;
    const advancedRuleExtraNotes = this.state.advancedRuleExtraNotes;
    const note = {};
    if (target === 'additionalFields') {
      const defaultValue = this.state.additionalFields.map(i => {
        note[i.title] = {
          type: i.type,
          value: {}
        }
      })
      if(!extraNote[productLineItemId]) {
        extraNote[productLineItemId] = note;
      }
      extraNote[productLineItemId][title].value[option] = checked;
    }
    if (target === 'advancedRuleExtraNotesSetting') {
      const defaultValue = this.state.advancedRuleExtraNotesSetting[productLineItemId].map(i => {
        note[i.title] = {
          type: i.type,
          value: {}
        }
      })
      if(!advancedRuleExtraNotes[productLineItemId]) {
        advancedRuleExtraNotes[productLineItemId] = note;
      }
      advancedRuleExtraNotes[productLineItemId][title].value[option] = checked;
    }
    this.props.form.setFieldsValue({
      [`checkbox_${title}_${productLineItemId}`]: 'checked',
    })
    this.setState({
      extraNote: extraNote,
      advancedRuleExtraNotes: advancedRuleExtraNotes
    });
  }

  handleRadioNoteChange(productLineItemId, { title, option, target}) {
    const extraNote = this.state.extraNote;
    const advancedRuleExtraNotes = this.state.advancedRuleExtraNotes;
    const note = {};
    if (target === 'additionalFields') {
      const defaultValue = this.state.additionalFields.map(i => {
        note[i.title] = {
          type: i.type,
          value: {}
        }
      })
      if(!extraNote[productLineItemId]) {
        extraNote[productLineItemId] = note;
      }
      extraNote[productLineItemId][title].value = option;
    }
    if (target === 'advancedRuleExtraNotesSetting') {
      const defaultValue = this.state.advancedRuleExtraNotesSetting[productLineItemId].map(i => {
        note[i.title] = {
          type: i.type,
          value: {}
        }
      })
      if(!advancedRuleExtraNotes[productLineItemId]) {
        advancedRuleExtraNotes[productLineItemId] = note;
      }
      advancedRuleExtraNotes[productLineItemId][title].value = option;
    }
    this.props.form.setFieldsValue({
      [`radio_${title}_${productLineItemId}`]: 'checked',
    })
    this.setState({
      extraNote: extraNote
    });
  }

  handleCustomNoteChange(productLineItemId, { title, value, target}) {
    const extraNote = this.state.extraNote;
    const advancedRuleExtraNotes = this.state.advancedRuleExtraNotes;
    const note = {};
    if (target === 'additionalFields') {
      const defaultValue = this.state.additionalFields.map(i => {
        note[i.title] = {
          type: i.type,
          value: {}
        }
      })
      if(!extraNote[productLineItemId]) {
        extraNote[productLineItemId] = note;
      }
      extraNote[productLineItemId][title].value = value;
    }
    if (target === 'advancedRuleExtraNotesSetting') {
      const defaultValue = this.state.advancedRuleExtraNotesSetting[productLineItemId].map(i => {
        note[i.title] = {
          type: 'note',
          value: {}
        }
      })
      if(!advancedRuleExtraNotes[productLineItemId]) {
        advancedRuleExtraNotes[productLineItemId] = note;
      }
      advancedRuleExtraNotes[productLineItemId][title].value = value
    }
    this.props.form.setFieldsValue({
      [`note_${title}_${productLineItemId}`]: value,
    })
    this.setState({
      extraNote: extraNote
    });
  }

  handleAdvanceNoteChange(productLineItemId, title, value) {
    const advancedRuleExtraNotes = this.state.advancedRuleExtraNotes;
    const note = {};
    const defaultValue = this.state.advancedRuleExtraNotesSetting[productLineItemId].map(i => {
      note[i] = {
        type: 'note',
        value: {}
      }
    })
    if(!advancedRuleExtraNotes[productLineItemId]) {
      advancedRuleExtraNotes[productLineItemId] = note;
    }
    advancedRuleExtraNotes[productLineItemId][title].value = value;
    this.props.form.setFieldsValue({
      [`AdvancedRuleNote_${title}_${productLineItemId}`]: value,
    })
    this.setState({
      advancedRuleExtraNotes: advancedRuleExtraNotes
    });
  }

  handleBack() {
    this.setState({ ...this.state, shouldHideStoreWideExchangesModal: true });
  }

  async handlegetReturnReasons() {
    try {
      const response = await axios.post("/api/visitors/return/reasons", {
        companyIdentifier: this.props.companyIdentifier,
        returnNumber: this.props.returnNumber,
      });
      if(response) {
        this.setState({
          returnReasons: response.data,
        });
      }
    } catch (err) {
      return message.error("Something went wrong please try again", 5);
    }
  };

  // Sets and submits the selected line-items for the return
  handleSubmit = (e) => {
    e.preventDefault();
    const { intl } = this.props;
    this.props.form.validateFields( async (err, values) => {
      const extraNote = this.state.extraNote;
      const { advancedRuleExtraNotes } = this.state || {};
      Object.keys(advancedRuleExtraNotes).forEach(key => {
        extraNote[key] = {
          ...extraNote[key],
          ...advancedRuleExtraNotes[key]
        };
      });
      
      values.extraNote = extraNote;
      values = this.addExchangeVariantIdToValues(values);
      if (err) {
        return message.error("Error. Please check the options you selected.", 5);
      }
      if (!this.productWasSelected(values)) {
        return message.info(
          intl.formatMessage({
            id:"app.visitor.order.checkProductSelected",
            description:"Check if the customer has selected at least one product to return",
            defaultMessage:"Please select at least one product to return.",
          })
          , 4);
      }
      if (!this.reasonWasSelected(values)) {
        return message.info(
          intl.formatMessage({
            id:"app.visitor.order.checkReasonSelected",
            description:"Check if the customer has selected a return reason for each product",
            defaultMessage:"Please select a reason for each item you return.",
          })
          , 4);
      }
      if (!this.resolutionWasSelected(values)) {
        return message.info(
          intl.formatMessage({
            id:"app.visitor.order.checkResolutionSelected",
            description:"Check if the customer has selected a resolution for each product",
            defaultMessage:"Please select a resolution for each item you return.",
          })
          , 4);
      }
      const companyIdentifier = this.props.companyIdentifier;
      const returnNumber = this.props.returnNumber;

      try {
        this.setState({
          submitButtonDisabled: true
        });
        let { advancedExchanges, expiredItems } = this.state;
        const response = await axios.post("/api/visitors/return/selections", {
          companyIdentifier,
          returnNumber,
          values,
          advancedExchanges,
          expiredItems,
        });
        if (response.data.status === "error") {
          this.setState({
            submitButtonDisabled: false
          });
          return message.error(response.data.error, 4);
        }

        const updatedReturn = response.data.returnData;

        this.props.updateBrandSettings({
          returnData: updatedReturn,
        });

        // if (this.context.featureCustomerPhotoUpload && this.props.returnSettings.customerPhotoUploadEnabled) {
        if (this.props.returnSettings.customerPhotoUploadEnabled || (this.props.returnData && this.props.returnData.ruleActionCustomerPhotoUpload)) {
          return this.props.history.push('/return/resolution');
        } else if (this.props.missingEmailForOrder) {
          return this.props.history.push('/return/info');
        } else {
          return this.props.history.push('/return/method');
        }
      
      } catch(err) {
        this.setState({
          submitButtonDisabled: false
        });
        return message.error("There was an error communicating with the server. Please try again.", 4);
      }

    });
  }

  showExchangeOptions(value = true) {
    this.setState({
      exchangeOptionsVisible: value,
    });
  }

  closeExchangeOptions(lineItemId = null) {
    if (lineItemId) 
    {
      this.props.form.setFieldsValue({
        [`resolution_${lineItemId}`]: undefined,
      })
    }
    this.setState({
      exchangeOptionsVisible: false,
    });
  }

  // Checks if a line-item has been selected or not
  productWasSelected = (values) => {
    let selectedProduct = false;
    this.props.orderData.line_items.map( (product) => {
      const markedProduct = values[`returnItemChecked_${product.id}`];
      if (markedProduct === true) {
        selectedProduct = true;
      }
    });
    return selectedProduct;
  }

  addExchangeVariantIdToValues = (values) => {
    this.props.orderData.line_items.map( (product) => {
      const selectedExchangeVariantId = this.getSelectedExchangeVariantId(product.id, this.state[`exchangeProductOptions_${product.id}`]) 
      if(selectedExchangeVariantId){
        values[`exchangeItem_${product.id}`] = selectedExchangeVariantId;
      }
    });
    return values;
  }

  // Checks if a return-reason has been selected for the line-item
  reasonWasSelected = (values) => {
    let reasonSelected = true;
    this.props.orderData.line_items.map( (product) => {
      const markedProduct = values[`returnItemChecked_${product.id}`];
      const selectedReason = values[`returnReason_${product.id}`];
      if (markedProduct === true && selectedReason == null) {
        reasonSelected = false;
      }
    });
    return reasonSelected;
  }

  // Checks if a resoution has been selected for the line-item
  resolutionWasSelected = (values) => {
    let resolutionSelected = true;
    this.props.orderData.line_items.map( (product) => {
      const markedProduct = values[`returnItemChecked_${product.id}`];
      const selectedResolution = values[`resolution_${product.id}`];
      if (markedProduct === true && selectedResolution == null) {
        resolutionSelected = false;
      }
    });
    return resolutionSelected;
  }

  // Formalizes selection of a resolution for the line-item. Differentiates between dynamic exchange and static other options
  // value is STRING --> selected option
  // product is { } --> selected line item
  resolutionSelected = async (value, product) => {
    const response = await axios.post("/api/visitors/return/checkInputChange", {
      companyIdentifier: this.props.companyIdentifier,
      returnNumber: this.props.returnNumber,
      lineItemId: product.id,
      action: value,
      reason: this.props.form.getFieldValue(`returnReason_${product.id}`),
    });
    //update action
    if (response.data.status === "error") {
      return message.error(response.data.error, 4);
    }
    this.setState({
      advancedRuleExtraNotesSetting: {
        ...this.state.advancedRuleExtraNotesSetting,
        [product.id]: response.data.titleExtraNotes
      },
      advancedRuleExtraNotes: {},
    });

    if (this.state.showIncentives) {
      const popoverElementsArray = document.querySelectorAll('.ant-popover');
      if (popoverElementsArray.length > 0) {
        popoverElementsArray.forEach(ele => {
          ele.style.display = 'none';
        })
      }
      let incentive
      if (this.state.dictatingResolutions?.includes(value) && this.state[`dictatResolution${product.id}`] !== false) {
        incentive = this.state.incentives.find(incentive => {
          return incentive.dictatResolution === value
        })
        this.setState({
          [`dictate${product.id}`]: true,
          [`dictateAmount${product.id}`]: `${incentive?.incentiveValueType === "fixed" ? `${Currencies[this.props.orderData.currency]}${incentive?.incentiveValue}` : `${incentive?.incentiveValue}%`}`,
          [`dictatResolution${product.id}`]: resolutionOptionsDetail[incentive?.incentiveResolution].identifier,
          [`excludeDictating${product.id}`]: true,
        })
      } else {
        this.setState({
          [`dictate${product.id}`]: false,
          [`${product.id}hideTimer${value}`]: true,
        })
      }
    }
    const lineItemId = product.id;
    this.props.form.setFieldsValue({
      [`returnItemChecked_${lineItemId}`]: true,
    });

    let { advancedExchanges } = this.state;
    if(advancedExchanges && advancedExchanges[`products_${product.id}` ])
    {
      advancedExchanges[`products_${product.id}` ] = []
      this.setState({
        advancedExchanges
      });
    }
  
    //if advancedExchange option is selected from portal
    if (value === "advancedExchange") {
      let selectedAdvancedExchangeOptions = this.state.advancedExchangeOptions[
        product.id
      ];
      // set the following information for the current product
      // to completed the functionality and control the UI
      this.setState(
        {
          advancedExchangeLineItemId: lineItemId,
          selectedAdvancedExchangeOptions,
          [`exchangeOptions_${lineItemId}`]: [],
          [`exchangeOptionsTitle_${lineItemId}`]: undefined,
          [`showNotesField_${lineItemId}_exchange`]: false,
          [`showNotesField_${lineItemId}_general`]: true,
        },
        () => this.showExchangeOptions()
      );
    } else if (value === "exchangeItem") {
      const response = await this.exchangeSelected(product);
      const { exchangeOptionsAvailable, exchangeOptionsTitle, exchangeOptions, exchangeProductOptions } = response;
      const resoulution = this.props.form.getFieldValue(`resolution_${product.id}`);
      if (exchangeOptionsAvailable === false) {
        this.setState({
          [`showNotesField_${lineItemId}_exchange`]: false,
          [`showNotesField_${lineItemId}_general`]: true,
        });
      } else if (resoulution === "exchangeItem"){
        this.setState({
          [`exchangeOptionsTitle_${lineItemId}`]: exchangeOptionsTitle,
          [`exchangeProductOptions_${lineItemId}`]: exchangeProductOptions,
          [`exchangeOptions_${lineItemId}`]: exchangeOptions,
          [`showNotesField_${lineItemId}_exchange`]: false,
          [`showNotesField_${lineItemId}_general`]: true,
        });
      }

    } else if (value === "storeWideExchange") {
      this.setState({
        [`showNotesField_${lineItemId}_general`]: true,
        [`showNotesField_${lineItemId}_exchange`]: false,
        [`exchangeOptions_${lineItemId}`]: [],
        [`exchangeOptionsTitle_${lineItemId}`]: undefined,
        showProducts:true,
        currentPoduct:product,
        shouldHideStoreWideExchangesModal: false,
      })
    } else {
      this.setState({
        [`exchangeOptions_${lineItemId}`]: [],
        [`exchangeOptionsTitle_${lineItemId}`]: undefined,
        [`showNotesField_${lineItemId}_exchange`]: false,
        [`showNotesField_${lineItemId}_general`]: true,
      });
    }
  }

  handleSelectedProduct = (product) => {
    const variant = product.selectedVariant
    const { id } =  this.state.currentPoduct
    this.updateExchangedProduct({
      lineItemIdToBeExchanged: id,
      productId: product.id,
      productName: product.title,
      variantId: variant.id,
      variantTitle: variant.title,
    })
  }

  hideProducts = (variant) => {
    if (!variant.product_id) {
      this.props.form.setFieldsValue({
        [`resolution_${this.state.currentPoduct.id}`]: undefined,
      })
    }
    this.setState({
      showProducts:false,
    })
  }

  // Loads the available exchange options for the product
  exchangeSelected = async (product) => {
    const companyIdentifier = this.props.companyIdentifier;
    const returnNumber = this.props.returnNumber;
    const lineItemId = product.id;
    const lineItemReturnQuantity = this.props.form.getFieldValue(`returnQuantity_${lineItemId}`);

    try {
      this.setState({
        loadingExchangeOptions: true
      });
    
      const response = await axios.post("/api/visitors/return/exchange", {
        companyIdentifier,
        returnNumber,
        lineItemId,
        lineItemReturnQuantity,
      });

      if (response.data.status === "error") {
        this.setState({
          loadingExchangeOptions: false
        });
        return message.error("There was an error loading your exchange options. Try again or fill out the notes field with your exchange instructions if the error persists.", 5);
      }

      return response.data;
    } catch(err) {
      this.setState({
        loadingExchangeOptions: false
      });
      return message.error("There was an error loading your exchange options. Try again or fill out the notes field with your exchange instructions if the error persists.", 5);
    }
  }

  // Loads return reasons for each line-item, option to shuffle reasons
  getReturnReasons() {
    let returnReasonsElements = [];

    if (this.state.returnReasons && this.state.returnReasons.length > 0) {
      let reasonsArray = this.state.returnReasons;

      if(this.props.returnSettings.shuffleReturnReasons){
        reasonsArray = this.shuffleArray(this.state.returnReasons);
      }
  
      returnReasonsElements = reasonsArray.map((reason, index) => {
        const locale = getLocale();
        const selectedLanguage = reason.translations.find((translation)=> translation.language === locale)
        return (
          <Option value={reason.reason} key={reason.id}>
            {selectedLanguage ? selectedLanguage.translation : reason.reason}
          </Option>
        );
      });
    } else {
      if (this.props.returnSettings && this.props.returnSettings.returnReasons) {
        const returnReasons = this.props.returnSettings.returnReasons;
        let arrayOfReturnReasons = returnReasons.split(/\r?\n/);
        
        if(this.props.returnSettings.shuffleReturnReasons)
        {
          arrayOfReturnReasons = this.shuffleArray(arrayOfReturnReasons);
        }
  
        returnReasonsElements = arrayOfReturnReasons.map((reason) => {
          return (
            <Option value={reason} key={reason}>
              {reason}
            </Option>
          );
        });
      }
    }
    return returnReasonsElements;
  }

  // Randomizes array of return reasons if enabled by the user
  shuffleArray(originalArray) {
    let array = [...originalArray];
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    while (0 !== currentIndex) {
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
    return array;
  }

  // reset any selected options from an advanced-exchange
  clearAdvancedExchange(lineItemIdToBeExchanged) {
    if (lineItemIdToBeExchanged) 
    {
      this.props.form.setFieldsValue({
        [`resolution_${lineItemIdToBeExchanged}`]: undefined,
      })
    }
    const { advancedExchanges } = this.state;
    
    advancedExchanges[
      `products_${lineItemIdToBeExchanged}`
    ] = [];
    
    advancedExchanges[
      `exchangedItemProductName_${lineItemIdToBeExchanged}`
    ] = undefined;
    advancedExchanges[
      `exchangedItemProductId_${lineItemIdToBeExchanged}`
    ] = undefined;
    advancedExchanges[
      `exchangedVariantId_${lineItemIdToBeExchanged}`
    ] = undefined;

    this.setState({
      [`advancedExchangeSelected_${lineItemIdToBeExchanged}`]: true,
      advancedExchanges,
    });
  }

  handleVisibilityChange = (visible, lineItemId, item) => {
    this.setState({
      [`${lineItemId}${item}timer`]: visible,
    });
  }

  onFinish = (lineItemId,item) => {
    this.setState({
      [`${lineItemId}hideTimer${item}`]: true,
      [`dictatResolution${lineItemId}`]: false,
      expiredItems : [
        ...this.state.expiredItems,
        {
          item,
          lineItemId,
        },
      ],
    }, () => this.getAvailableOptions(lineItemId))
  }

  // creating return options dynamically for every product
  getAvailableOptions(lineItemId, price) {
    const customOptions = [
      "refundToCustom1",
      "refundToCustom2",
      "refundToCustom3",
    ];
    const { formatMessage } = this.props.intl;
    let availableOptions = this.state.availableResolutionOptions.map(
      (item) => {
        if (this.state.showIncentives) {
          const incentive = this.state.incentivesResolutions.includes(item) ? this.state.incentives?.find(incentive => {
            return item === incentive?.incentiveResolution
          }) : undefined

          if (incentive && (!incentive.dictatIncentive || this.state[`excludeDictating${lineItemId}`])) {

            if (incentive.enableTimer && !this.state[`${lineItemId}hideTimer${item}`]) {
              return <Option
                value={resolutionOptionsDetail[item].identifier}
                key={resolutionOptionsDetail[item].identifier}
                className="inscentivesBadge"
              >
                <Popover
                  trigger="hover"
                  style={{backgroundColor: this.props.brandIncentivesColor }} 
                  placement="right"  
                  title={""} 
                  visible={this.state[`${lineItemId}${item}timer`]}
                  onVisibleChange={(value) => this.handleVisibilityChange(value, lineItemId, item)} 
                  content={
                    <Countdown 
                      title={<span style={{color:"red"}}>{formatMessage({
                        id:"app.visitor.incentive.timerEnding",
                        description:"duration of incentive timer",
                        defaultMessage:"Offer ends in",
                      })}</span>} 
                      value={this.state[`${lineItemId}deadline`] + ((parseFloat(incentive.timerValue) -1) * 60000)} // Convert minutes to milliseconds
                      format="mm:ss" 
                      onFinish={() => this.onFinish(lineItemId,item)} 
                      arrowPointAtCenter
                    />
                  }
                >
                  <Badge
                    style={{backgroundColor:this.props.brandIncentivesColor}} 
                    count={`${incentive.incentiveValueType === "fixed" ? `+ ${Currencies[this.props.orderData.currency]}${incentive.incentiveValue}` : `+${incentive.incentiveValue}%`} ${formatMessage({
                      id:`${incentive.incentiveType === "Discount Code" ? "app.visitor.incentive.discountCode" : "app.visitor.resolutions.storeCreditTitle"}`,
                      description:"type of incentive discount code/store credit",
                      defaultMessage:incentive.incentiveType,
                    })}`} offset={[70,0]}>
                    {customOptions.includes(resolutionOptionsDetail[item].identifier)
                      ? this.props.returnSettings[
                          `${resolutionOptionsDetail[item].identifier}Label`
                      ]
                      : <span style={{ width: "70%"}}>{resolutionOptionsDetail[item].title}</span>}
                  </Badge>
                </Popover>
              </Option>

            } else if (!incentive.enableTimer) {
              return <Option
                value={resolutionOptionsDetail[item].identifier}
                key={resolutionOptionsDetail[item].identifier}
                className="inscentivesBadge"
              >
                <Badge
                  style={{backgroundColor:this.props.brandIncentivesColor}} 
                  count={`${incentive.incentiveValueType === "fixed" ? `+${Currencies[this.props.orderData.currency]} ${incentive.incentiveValue}` : `+${incentive.incentiveValue}%`} ${formatMessage({
                    id:`${incentive.incentiveType === "Discount Code" ? "app.visitor.incentive.discountCode" : "app.visitor.resolutions.storeCreditTitle"}`,
                    description:"type of incentive discount code/store credit",
                    defaultMessage:incentive.incentiveType,
                  })}`} offset={[70,0]}>
                  {customOptions.includes(resolutionOptionsDetail[item].identifier)
                    ? this.props.returnSettings[
                        `${resolutionOptionsDetail[item].identifier}Label`
                    ]
                    : <span style={{ width: "70%"}}>{resolutionOptionsDetail[item].title}</span>}
                </Badge>
              </Option>

            }
          }
        }
        return (
          <Option
            value={resolutionOptionsDetail[item].identifier}
            key={resolutionOptionsDetail[item].identifier}
          >
            {customOptions.includes(resolutionOptionsDetail[item].identifier)
              ? this.props.returnSettings[
                  `${resolutionOptionsDetail[item].identifier}Label`
              ]
              : resolutionOptionsDetail[item].title}
          </Option>
        );
      }
    );

    if (
      this.state.advancedExchangeOptions[lineItemId] &&
      this.state.advancedExchangeOptions[lineItemId].length > 0
    ) {
      const incentive = this.state.incentivesResolutions.includes("advancedExchange") ? this.state.incentives?.find(incentive => {
        return incentive?.incentiveResolution === "advancedExchange"
      }) : undefined;

      if (this.state.showIncentives && incentive?.incentiveResolution === "advancedExchange" && (!incentive.dictatIncentive || this.state[`excludeDictating${lineItemId}`]) && !this.state[`${lineItemId}hideTimeradvancedExchange`]) {
        if (incentive.enableTimer) {
          availableOptions.unshift(
            <Option
              value={resolutionOptionsDetail["advancedExchange"].identifier}
              key={resolutionOptionsDetail["advancedExchange"].identifier}
              className="inscentivesBadge"
            >
              <Popover
                trigger="hover"
                style={{backgroundColor: this.props.brandIncentivesColor }} 
                placement="right"  
                title={""} 
                visible={this.state[`${lineItemId}advancedExchangetimer`]}
                onVisibleChange={(value) => this.handleVisibilityChange(value, lineItemId, "advancedExchange")} 
                content={
                  <Countdown 
                    title={<span style={{color:"red"}}>{formatMessage({
                      id:"app.visitor.incentive.timerEnding",
                      description:"duration of incentive timer",
                      defaultMessage:"Offer ends in",
                    })}</span>} 
                    value={this.state[`${lineItemId}deadline`] + ((parseFloat(incentive.timerValue) -1) * 60000)} // Convert minutes to milliseconds
                    format="mm:ss" 
                    onFinish={() => this.onFinish(lineItemId,"advancedExchange")} 
                    arrowPointAtCenter
                  />
                }
              >
                <Badge
                  style={{backgroundColor:this.props.brandIncentivesColor}} 
                  count={`${incentive.incentiveValueType === "fixed" ? `+ ${Currencies[this.props.orderData.currency]}${incentive.incentiveValue}` : `+${incentive.incentiveValue}%`} ${formatMessage({
                    id:`${incentive.incentiveType === "Discount Code" ? "app.visitor.incentive.discountCode" : "app.visitor.resolutions.storeCreditTitle"}`,
                    description:"type of incentive discount code/store credit",
                    defaultMessage:incentive.incentiveType,
                  })}`} offset={[70,0]}>
                  <span style={{ width:"70%"}}>{resolutionOptionsDetail["advancedExchange"].title}</span>
                </Badge>
              </Popover>
            </Option>
          )
        } else {
          availableOptions.unshift(
            <Option
              value={resolutionOptionsDetail["advancedExchange"].identifier}
              key={resolutionOptionsDetail["advancedExchange"].identifier}
              className="inscentivesBadge"
            >
              <Badge 
                style={{backgroundColor:this.props.brandIncentivesColor}} 
                count={`${incentive.incentiveValueType === "fixed" ? `+${Currencies[this.props.orderData.currency]} ${incentive.incentiveValue}` : `+${incentive.incentiveValue}%`} ${incentive.incentiveType}`} offset={[70,0]}
              >
                <span style={{ width:"70%"}}>{resolutionOptionsDetail["advancedExchange"].title}</span>
              </Badge>
            </Option>
          );
        }
      } else {
        availableOptions.unshift(
          <Option
            value={resolutionOptionsDetail["advancedExchange"].identifier}
            key={resolutionOptionsDetail["advancedExchange"].identifier}
          >
            {resolutionOptionsDetail["advancedExchange"].title}
          </Option>
        );
      }
    }

    return availableOptions;
  }

  // Set the advance exchange information about the product based on lineItemId
  updateExchangedProduct = (productInfo) => {
    const {
      lineItemIdToBeExchanged,
      productId,
      productName,
      variantId,
    } = productInfo;
    let { advancedExchanges } = this.state;

    advancedExchanges[
      `products_${lineItemIdToBeExchanged}`
    ] = [productInfo];
    
    advancedExchanges[
      `exchangedItemProductName_${lineItemIdToBeExchanged}`
    ] = productName;
    advancedExchanges[
      `exchangedItemProductId_${lineItemIdToBeExchanged}`
    ] = productId;
    advancedExchanges[
      `exchangedVariantId_${lineItemIdToBeExchanged}`
    ] = variantId;

    this.setState({
      [`advancedExchangeSelected_${lineItemIdToBeExchanged}`]: true,
      advancedExchanges,
      advancedExchangeLineItemId: null,
    });
  };

  // Updating the quantity of a line-item can change availability of e.g. exchange items. Hence reset any previous selections
  updateQuantityOfLineItem(value, lineItemId, variantId) {
    this.props.form.setFieldsValue({
      [`resolution_${lineItemId}`]: undefined,
    });
    const itemQuantity = this.state.itemQuantity;
    itemQuantity[`quantity_${variantId}`] = value;
    this.setState({
      [`exchangeOptions_${lineItemId}`]: [],
      [`exchangeOptionsTitle_${lineItemId}`]: undefined,
      [`showNotesField_${lineItemId}_exchange`]: false,
      [`showNotesField_${lineItemId}_general`]: false,
      itemQuantity,
    });
  }
  
  onChangeReason = async (value, lineItemId) => {
    //check reason with advance rule
    const response = await axios.post("/api/visitors/return/checkInputChange", {
      companyIdentifier: this.props.companyIdentifier,
      returnNumber: this.props.returnNumber,
      lineItemId: lineItemId,
      reason: value,
      action:  this.props.form.getFieldValue(`resolution_${lineItemId}`),
    });
    //update action
    if (response.data.status === "error") {
      return message.error(response.data.error, 4);
    }
    this.setState({
      advancedRuleExtraNotesSetting: {
        ...this.state.advancedRuleExtraNotesSetting,
        [lineItemId]: response.data.titleExtraNotes
      },
    });
  };

  handleExchangeVariantPropertySelection(value, productOption, productId){
    const newExchangeProductOptions = [];
    for(const exchangeProductOption of this.state[`exchangeProductOptions_${productId}`]){
      if(exchangeProductOption.id === productOption.id){
        newExchangeProductOptions.push({...productOption, valueSelected: value})
      }
      else{
        newExchangeProductOptions.push(exchangeProductOption)
      }
    }
    for(const option of newExchangeProductOptions){
      if(option.valueSelected){
        const isDisabled = this.isExchangeVariantPropertyDisabled(option.valueSelected, option, productId, newExchangeProductOptions);
        if(isDisabled.disabled){
          option.valueSelected = undefined;
          this.props.form.setFieldsValue({
            [`exchangeItem_${productId}_${option.id}`]: undefined,
          });
        }
      }
    }
    this.setState({
      ...this.state,
      [`exchangeProductOptions_${productId}`]: newExchangeProductOptions
    })

    this.props.form.setFieldsValue({
      [`exchangeItem_${productId}_${productOption.id}`]: value,
    });
  }

  isExchangeVariantPropertyDisabled(optionValue, productOption, productId, exchangeProductOptions){
    let exchangeOptions = [...this.state[`exchangeOptions_${productId}`]];
    const optionIndex = exchangeProductOptions.findIndex(option => option.id === productOption.id);
    const selectedOptions = exchangeProductOptions.slice(0, optionIndex).filter(productOption => productOption.valueSelected);
    for(const selectedOption of selectedOptions){
      exchangeOptions=exchangeOptions.filter(exchangeOption => {
        const variantOption = exchangeOption.variantProductOptions.find(variantOption => variantOption.id === selectedOption.id && variantOption.value === selectedOption.valueSelected);
        if(variantOption){
          return true;
        }
        return false;
      })
    }
    let disabledReason = "";
    exchangeOptions=exchangeOptions.filter(exchangeOption => {
      const hasProperty = exchangeOption.variantProductOptions.find(variantOption => variantOption.id === productOption.id && variantOption.value === optionValue);
      if(hasProperty && exchangeOption.disabled === false){
        return true;
      } else if(hasProperty && exchangeOption.disabled === true) {
        disabledReason = exchangeOption.disabledReason ? exchangeOption.disabledReason : '';
      }
      return false;
    })
    return {
      disabled: exchangeOptions.length === 0,
      disabledReason
    }
  }

  getSelectedExchangeVariantId(productId, exchangeProductOptions){
    const areAllOptionsSelected = exchangeProductOptions?.every(productOption => productOption.valueSelected)
    const optionVariant = this.state[`exchangeOptions_${productId}`]?.find(exchangeOption => {
      if(exchangeOption.disabled){
        return false
      }
      for(
        const variantOption of exchangeOption.variantProductOptions
      ){
        const selectedOption = exchangeProductOptions.find(productOption => productOption.id === variantOption.id)
        if(selectedOption.valueSelected !== variantOption.value){
          return false
        }
      }
      return true
    })
    
    if(areAllOptionsSelected && optionVariant){
      return optionVariant.exchangeVariantId;
    }
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { formatMessage } = this.props.intl;
    // TODO: upgrade antd to 5.x and remove this
    // add css in antd after render
    setTimeout(() => {
      const doc = document.getElementsByClassName('ant-form-item-label');
      for(let i = 0 ; i < doc.length ; i++) {
        doc[i].style.whiteSpace = 'break-spaces';
        doc[i].style.width = '100%';
      }
    }, 0);
    // TODO end
    const placeholderInput = defineMessages({
      notesExchange: {
        id: "app.visitor.order.notesExchange",
        description: "additional notes for exchange of product",
        defaultMessage: "Please indicate the details of your exchange.",
      },
      notesGeneral: {
        id: "app.visitor.order.notesGeneral",
        description: "additional notes for everything other than an exchange",
        defaultMessage: "Optional notes. Give us further details about your return.",
      },
      dictateMessage: {
        id:"app.visitor.incentive.dictatMessage",
        description:"type of incentive discount code/store credit",
        defaultMessage:"Get up to {bonusAmount} if you opt for {dictateResolution}",
      },
      disabledProduct: {
        id:"app.visitor.order.disabledProduct",
        description:"message for the products disbaled from advanced rules.",
        defaultMessage:"This product is ineligible for a return. For more information, contact us at {email_address}.",
      }
    });

    let productList = !this.props.orderData.line_items
      ? "Loading"
      : this.props.orderData.line_items.map( (product, index) => {
        const insertDivider = (index !== this.props.orderData.line_items.length-1)
          ? <Row>
            <Col>
              <Divider />
            </Col>
          </Row>
          : "";
        let quantitySelectionArray = [];
        for (let i=1;i<=product.quantity;i++) {
          quantitySelectionArray.push(i);
        }
        let quantitySelectionElements = quantitySelectionArray.map( (item) => {
          return <Option value={ item } key={ item }>{ item }</Option>;
        });

        const productElement =
          (
            <React.Fragment key={ product.id }>
              <Row type="flex" justify="center" align="middle" className={this.state.showIncentives ? "returnProducts u-rowGap--md" : "u-rowGap--md"}>
                <Col md={2} xs={24}>
                  <FormItem>
                    { getFieldDecorator(`returnItemChecked_${product.id}`, {
                      valuePropName: "checked",
                      initialValue: false,
                    })(
                      <Switch disabled={product.isExcludedFromReturns || product.deletedFromStore || product.disabledFromRules} style={{ marginBottom: 5 }} checkedChildren={<Icon type="check" />} unCheckedChildren={<Icon type="close" />} />
                    )}
                  </FormItem>
                  {
                    product.isExcludedFromReturns
                      ? (<FormattedMessage
                        id="app.visitor.order.notEligible"
                        description="Product is not eligible for return"
                        defaultMessage="Not eligible for return"
                        values={{
                        }}
                      />)
                      : ""
                  }
                </Col>
                <Col md={4} xs={24}>
                  <img src={ this.props.productData[`${product.product_id}-${product.variant_id}`] } style={{ maxHeight: 100 }} />
                </Col>
                <Col md={6} xs={24}>
                  <h2>{ product.name }</h2>
                  <p>
                    <FormattedMessage
                      id="app.visitor.order.orderNumber"
                      description="Order number"
                      defaultMessage="Order number"
                      values={{
                      }}
                    />: { this.props.orderNumber || this.props.orderData.name.replace("#","") }
                  </p>
                  <p>
                    <FormattedMessage
                      id="app.visitor.order.quantityInOrder"
                      description="Quantity of product in order"
                      defaultMessage="Quantity"
                      values={{
                      }}
                    />: { product.quantity }
                  </p>
                  {
                    product.deletedFromStore
                      ? (
                        <Tooltip
                          placement="bottom"
                          title={<FormattedMessage
                            id="app.visitor.order.toolTip"
                            description="This product cannot be returned as it has been removed from the store"
                            defaultMessage="This product cannot be returned as it has been removed from the store"
                            values={{
                            }}
                          />}
                        >
                          <span><FormattedMessage
                            id="app.visitor.order.deletedProduct"
                            description="This product cannot be returned"
                            defaultMessage="This product cannot be returned"
                            values={{
                            }}
                          /></span> <Icon type="info-circle" />
                        </Tooltip>
                      )
                      : null
                  }
                  {
                    product.disabledFromRules
                      ? (
                        <FormattedMessage
                          {...placeholderInput.disabledProduct}
                          values={{
                            email_address: <a style={{ textDecoration: "underline", color: this.props.brandColorText, fontStyle: this.props.brandFontStyle}} target="_blank" href={"mailto:" + this.props.contactEmail}>{this.props.contactEmail}</a>
                          }}
                        />
                      )
                      : null
                  }
                </Col>
                <Col md={8} xs={24} style={{ display: "flex", flexDirection: "column", rowGap: "10px" }}>
                  {
                    product.quantity > 1
                      ? (
                        <Row type="flex" justify="center" align="top">
                          <Col md={24} xs={24}>
                            <FormItem
                              colon={false}
                              label={
                                <FormattedMessage
                                  id="app.visitor.order.quantityInReturn"
                                  description="Quantity of product in return"
                                  defaultMessage="Quantity"
                                  values={{
                                  }}
                                />
                              }
                              {...formItemLayout}
                            >
                              { getFieldDecorator(`returnQuantity_${product.id}`, {
                                initialValue: "1",
                              })(
                                <Select
                                  disabled={product.isExcludedFromReturns || product.deletedFromStore || product.disabledFromRules}
                                  onChange={ (value) => { this.updateQuantityOfLineItem(value, product.id, product.variant_id) }}
                                >
                                  { quantitySelectionElements }
                                </Select>
                              )}
                            </FormItem>
                          </Col>
                        </Row>
                      )
                      : null
                  }
                  <Row type="flex" justify="center" align="top">
                    <Col  md={24} xs={24}>
                      <FormItem
                        colon={false}
                        label={
                          <FormattedMessage
                            id="app.visitor.order.selectResolution"
                            description="Customer selects the desired action for a product"
                            defaultMessage="Action"
                            values={{
                            }}
                          />
                        }
                        {...formItemLayout}
                      >
                        { getFieldDecorator(`resolution_${product.id}`, {
                          // initialValue: "1",
                        })(
                          <Select
                            onMouseEnter={()=>{
                              if (!this.state[`${product.id}Initiated`]) {
                                this.setState({
                                  [`${product.id}Initiated`]: true,
                                  [`${product.id}deadline`] : Date.now() + 60000,
                                })
                              }
                            }}
                            id={`resolution_${product.id}`}
                            key={`resolution_${product.id}`}
                            onChange={(value) =>
                              this.resolutionSelected(value, product)
                            }
                            disabled={product.isExcludedFromReturns || product.deletedFromStore || product.disabledFromRules}
                          >
                            {this.getAvailableOptions(product.id, product.price)}
                          </Select>
                        )}
                        {this.state[`dictatResolution${product.id}`] && this.state[`dictate${product.id}`] && 
                          <span style={{color: "red", fontSize: "small"}}> 
                            <FormattedMessage
                              {...placeholderInput.dictateMessage}
                              values={{bonusAmount: this.state[`dictateAmount${product.id}`], dictateResolution: this.state[`dictatResolution${product.id}`] === "storeCredit" ? resolutionOptionsDetail.storeCredit.title : exchangeResolutionTranslation?.exchange?.title}}
                            />
                          </span>
                        }
                      </FormItem>
                    </Col>
                  </Row>
                  { 
                    this.state[`exchangeOptions_${product.id}`] && this.state[`exchangeOptions_${product.id}`].length > 0
                      ? (
                        <>
                          {this.state[`exchangeProductOptions_${product.id}`].map((productOption, index)=>{
                            return(
                              <Row type="flex" justify="space-around" align="middle">
                                <Col md={24} xs={24}>
                                  <FormItem
                                    colon={false}
                                    label={
                                      <FormattedMessage
                                        id="app.visitor.order.exchangeOptionsDescription"
                                        description="Description for dropdown menu containing the exchange variations"
                                        defaultMessage={`Choose new {variableForOptionTitle}`}
                                        values={{
                                          variableForOptionTitle: this.state[`exchangeOptions_${product.id}`].length === 1 ? 'product':productOption.name,
                                        }}
                                      /> 
                                    }
                                    {...formItemLayout}
                                  >
                                    { getFieldDecorator(this.state[`exchangeOptions_${product.id}`].length === 1 ? `exchangeItem_${product.id}`:`exchangeItem_${product.id}_${productOption.id}`, {
                                      rules: 
                                    [{ 
                                      required: true,
                                      message: <FormattedMessage
                                        id="app.visitor.order.exchangeOptionsErrorRequired"
                                        description="Customer missed filling out the exchange details. This is the associated error message."
                                        defaultMessage="Please select a valid {productOptionName}."
                                        values={{
                                          productOptionName: productOption.name,
                                        }}
                                      />
                                    }],
                                    })(
                                      <>
                                        {this.state[`exchangeOptions_${product.id}`].length === 1 ? (
                                          <Select
                                            onChange={()=>this.props.form.setFieldsValue({
                                              [`exchangeItem_${product.id}`]: this.state[`exchangeOptions_${product.id}`][0].exchangeVariantId
                                            })}
                                          >
                                            <Option value={ this.state[`exchangeOptions_${product.id}`][0].exchangeVariantId } key={ this.state[`exchangeOptions_${product.id}`][0].exchangeVariantId } disabled={this.state[`exchangeOptions_${product.id}`][0].disabled} style={{color: this.state[`exchangeOptions_${product.id}`][0].disabled && "rgba(0, 0, 0, 0.25)"}}>{ this.state[`exchangeOptions_${product.id}`][0].disabledReason ? `${product.title} (${this.state[`exchangeOptions_${product.id}`][0].disabledReason})` : product.title }</Option>
                                          </Select>
                                        ):(
                                          <Select
                                            onChange={(value,option)=>this.handleExchangeVariantPropertySelection(value, productOption, product.id)}
                                            value={productOption.valueSelected}
                                          >
                                            {
                                              productOption.values.map((option) =>{
                                                const isDisabled = this.isExchangeVariantPropertyDisabled(option, productOption, product.id, this.state[`exchangeProductOptions_${product.id}`]);
                                                return (
                                                  <Option value={ option } key={ option } disabled={isDisabled.disabled} style={{color: isDisabled.disabled && "rgba(0, 0, 0, 0.25)"}}>{ (isDisabled.disabledReason && isDisabled.disabled) ? `${option} (${isDisabled.disabledReason})` : option }</Option>
                                                )
                                              })
                                            }
                                          </Select>
                                        )}
                                      </>
                                    )}
                                  </FormItem>
                                </Col>
                              </Row>
                            )
                          })}
                        </>
                      )
                      : null
                  }
                  {/* displaying product selected for advanced exchange or store-wide exchange */}
                  {this.state.advancedExchanges[
                    `products_${product.id}`
                  ]&&this.state.advancedExchanges[
                    `products_${product.id}`
                  ].length > 0 ? (
                      <Row span={24} type="flex" justify="center" align="top">
                        <Col span={24}>
                          <List
                            span={24}
                            style={{ "borderRadius": 4, "background": "#fbfbfd" }}
                            bordered={true}
                            itemLayout="horizontal"
                            dataSource={this.state.advancedExchanges[`products_${product.id}`]}
                            renderItem={(item) => (
                              <List.Item span={24} style={{ position: 'relative' }}>
                                <Button
                                  style={{
                                    position: 'absolute',
                                    background: 'white',
                                    color: 'black',
                                    padding: '0 7px',
                                    borderRadius: '20px',
                                    top: '-10px',
                                    right: '-10px',
                                  }}
                                  onClick={() => this.clearAdvancedExchange(item.lineItemIdToBeExchanged)}
                                >
                                x
                                </Button>
                              
                                <List.Item.Meta
                                  avatar={<img width='100%' src={item.productImage} /> }
                                  title={
                                    <Badge
                                      count={formatMessage({
                                        id:"app.visitor.exchange.recieveItem",
                                        description:"title for item selected for exchange",
                                        defaultMessage:"You'll receive",
                                      })}
                                      style={{ backgroundColor: '#52c41a'}}
                                    />
                                  }
                                  description={item.productName + ' - ' + item.variantTitle}
                                />
                              </List.Item>
                            )}
                          />
                        </Col>
                      </Row>
                    ) : null}
                  <Row type="flex" justify="space-around" align="middle">
                    <Col md={24} xs={24}>
                      <FormItem
                        colon={false}
                        label={
                          <FormattedMessage
                            id="app.visitor.order.returnReason"
                            description="Reason for return of the product"
                            defaultMessage="Reason"
                            values={{
                            }}
                          />
                        }
                        {...formItemLayout}
                      >
                        { getFieldDecorator(`returnReason_${product.id}`, {
                        })(
                          <Select disabled={product.isExcludedFromReturns || product.deletedFromStore || product.disabledFromRules} onChange={(value) => {
                            this.onChangeReason(value, product.id)
                          }}>
                            { this.getReturnReasons() }
                          </Select>
                        )}
                      </FormItem>
                    </Col>
                  </Row>
                  {
                    this.state[`showNotesField_${product.id}_exchange`] === true
                      ? (
                        <Row type="flex" justify="center" align="top">
                          <Col  md={24} xs={24}>
                            <FormItem
                              colon={false}
                              label={this.props.returnSettings?.customizeNote?.title ? this.props.returnSettings?.customizeNote?.title :
                                <FormattedMessage
                                  id="app.visitor.order.notesTitleExchange"
                                  description="Customer exchanges product and explains the exchange details"
                                  defaultMessage="Customer notes"
                                  values={{
                                  }}
                                />
                              }
                              {...formItemLayout}
                            >
                              { getFieldDecorator(`notes_${product.id}`, {
                              // initialValue: "1",
                                rules: 
                                [{ 
                                  required: this.props.returnSettings?.customizeNote?.isRequire || true,
                                  message: <FormattedMessage
                                    id="app.visitor.order.notesExchangeErrorRequired"
                                    description="Customer missed filling out the exchange details. This is the associated error message."
                                    defaultMessage="Please indicate the details of your exchange."
                                    values={{
                                    }}
                                  />
                                },
                                ],
                              })(
                                <p>
                                  <TextArea
                                    autosize
                                    placeholder={formatMessage(
                                      placeholderInput.notesExchange
                                    )}
                                  />
                                </p>
                              )}
                            </FormItem>
                          </Col>
                        </Row>
                      ) : null}

                  {this.state[
                    `showNotesField_${product.id}_general`
                  ] === true ? (
                      <Row type="flex" justify="center" align="top">
                        <Col md={24} xs={24}>
                          <FormItem
                            colon={false}
                            label={this.props.returnSettings?.customizeNote?.title ? this.props.returnSettings?.customizeNote?.title :
                              <FormattedMessage
                                id="app.visitor.order.notesTitleExchange"
                                description="Customer exchanges product and explains the exchange details"
                                defaultMessage="Customer notes"
                                values={{}}
                              />
                            }
                            {...formItemLayout}
                          >
                            { getFieldDecorator(`notes_${product.id}`, {
                              // initialValue: "1",
                              rules: [{ 
                                required: this.props.returnSettings?.customizeNote?.isRequire || false,
                                message: <FormattedMessage
                                  id="app.visitor.order.notesExchangeErrorRequired"
                                  description="Customer missed filling out the customer note. This is the associated error message."
                                  defaultMessage="Please indicate the details of your return."
                                />},
                              ],
                            })(
                              <p>
                                <TextArea
                                  autosize
                                  placeholder={ formatMessage(placeholderInput.notesGeneral) }
                                />
                              </p>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    )
                    : null
                  }
                  
                  {this.state[
                    `showNotesField_${product.id}_general`
                  ] === true && this.state.additionalFields.length > 0 && this.state.additionalFields.map((i, index) => {
                    if(i.type === 'note') {
                      return <Row type="flex" justify="center" align="top">
                        <Col md={24} xs={24} style={{ whiteSpace: 'break-spaces' }}>
                          <FormItem
                            colon={false}
                            label={i.title}
                            {...formItemLayout}
                            style={{ whiteSpace: 'break-spaces' }}
                          >
                            { getFieldDecorator(`notes_${i.title}_${product.id}`, {
                              // initialValue: "1",
                              rules: [{ 
                                required: i.isRequire && this.state[
                                  `showNotesField_${product.id}_general`
                                ] === true,
                                message: <FormattedMessage
                                  id="app.visitor.order.notesExchangeErrorRequired"
                                  description="Customer missed filling out the customer note. This is the associated error message."
                                  defaultMessage="Please indicate the details of your return."
                                />},
                              ],
                            })(
                              <TextArea onChange={(e) => { this.handleCustomNoteChange(product.id, {
                                title: i.title,
                                value: e.target.value,
                                target: 'additionalFields'
                              }) }}/>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    } else if (i.type === 'checkbox') {
                      return <Row type="flex" justify="center" align="top">
                        <Col md={24} xs={24}>
                          <FormItem
                            colon={false}
                            label={i.title}
                            {...formItemLayout}
                          >
                            { getFieldDecorator(`checkbox_${i.title}_${product.id}`, {
                              // initialValue: "1",
                              rules: [{ 
                                required: i.isRequire && this.state[
                                  `showNotesField_${product.id}_general`
                                ] === true,
                                message: <FormattedMessage
                                  id="app.visitor.order.notesExchangeErrorRequired"
                                  description="Customer missed filling out the customer note. This is the associated error message."
                                  defaultMessage="Please indicate the details of your return."
                                />},
                              ],
                            })(
                              <React.Fragment>
                                {i.options.map((option) => {
                                  return <Checkbox key={option} onChange={(e) => {
                                    this.handleCheckboxChange(product.id, {
                                      title: i.title, 
                                      option, 
                                      checked: e.target.checked,
                                      target: 'additionalFields'
                                    });
                                  }} style={{ marginLeft: 0 }}>{option}</Checkbox>
                                })}
                              </React.Fragment>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    } else if (i.type === 'radio-button') {
                      return <Row key={index} type="flex" justify="center" align="top">
                        <Col md={24} xs={24}>
                          <FormItem
                            colon={false}
                            label={i.title}
                            {...formItemLayout}
                          >
                            { getFieldDecorator(`radio_${i.title}_${product.id}`, {
                              // initialValue: "1",
                              rules: [{ 
                                required: i.isRequire && this.state[
                                  `showNotesField_${product.id}_general`
                                ] === true,
                                message: <FormattedMessage
                                  id="app.visitor.order.notesExchangeErrorRequired"
                                  description="Customer missed filling out the customer note. This is the associated error message."
                                  defaultMessage="Please indicate the details of your return."
                                />},
                              ],
                            })(
                              <Radio.Group>
                                {i.options.map((option) => {
                                  return <Radio key={option} value={option} onChange={(e) => {
                                    this.handleRadioNoteChange(product.id, {
                                      title: i.title, 
                                      option,
                                      target: 'additionalFields'
                                    });
                                  }}>{option}</Radio>
                                })}
                              </Radio.Group>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    }
                  })}
                  {this.state.advancedRuleExtraNotesSetting[`${product.id}`]?.length > 0 && this.state.advancedRuleExtraNotesSetting[`${product.id}`].map((i, index) => {
                    if(i.type === 'note') {
                      return <Row key={index} type="flex" justify="center" align="top">
                        <Col md={24} xs={24} style={{ whiteSpace: 'break-spaces' }}>
                          <FormItem
                            colon={false}
                            label={i.title}
                            {...formItemLayout}
                            style={{ whiteSpace: 'break-spaces' }}
                          >
                            { getFieldDecorator(`note_${i.title}_${product.id}`, {
                              // initialValue: "1",
                              rules: [{ 
                                required: i.isRequired && this.state[
                                  `showNotesField_${product.id}_general`
                                ] === true,
                                message: <FormattedMessage
                                  id="app.visitor.order.notesExchangeErrorRequired"
                                  description="Customer missed filling out the customer note. This is the associated error message."
                                  defaultMessage="Please indicate the details of your return."
                                />},
                              ],
                            })(
                              <TextArea onChange={(e) => { this.handleCustomNoteChange(product.id, {
                                title: i.title,
                                value: e.target.value,
                                target: 'advancedRuleExtraNotesSetting'
                              }) }}/>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    } else if (i.type === 'checkbox') {
                      return <Row key={index} type="flex" justify="center" align="top">
                        <Col md={24} xs={24}>
                          <FormItem
                            colon={false}
                            label={i.title}
                            {...formItemLayout}
                          >
                            { getFieldDecorator(`checkbox_${i.title}_${product.id}`, {
                              initialValue: false,
                              rules: [{ 
                                required: i.isRequired && this.state[
                                  `showNotesField_${product.id}_general`
                                ] === true,
                                message: <FormattedMessage
                                  id="app.visitor.order.notesExchangeErrorRequired"
                                  description="Customer missed filling out the customer note. This is the associated error message."
                                  defaultMessage="Please indicate the details of your return."
                                />},
                              ],
                            })(
                              <React.Fragment>
                                {i.options.map((option) => {
                                  return <Checkbox key={option} defaultChecked={false} onChange={(e) => {
                                    this.handleCheckboxChange(product.id, {
                                      title: i.title, 
                                      option, 
                                      checked: e.target.checked,
                                      target: 'advancedRuleExtraNotesSetting'
                                    });
                                  }}>{option}</Checkbox>
                                })}
                              </React.Fragment>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    } else if (i.type === 'radio-button') {
                      return <Row key={index} type="flex" justify="center" align="top">
                        <Col md={24} xs={24}>
                          <FormItem
                            colon={false}
                            label={i.title}
                            {...formItemLayout}
                          >
                            { getFieldDecorator(`radio_${i.title}_${product.id}`, {
                              // initialValue: "1",
                              rules: [{ 
                                required: i.isRequired && this.state[
                                  `showNotesField_${product.id}_general`
                                ] === true,
                                message: <FormattedMessage
                                  id="app.visitor.order.notesExchangeErrorRequired"
                                  description="Customer missed filling out the customer note. This is the associated error message."
                                  defaultMessage="Please indicate the details of your return."
                                />},
                              ],
                            })(
                              <Radio.Group>
                                {i.options.map((option) => {
                                  return <Radio key={option} value={option} onChange={(e) => {
                                    this.handleRadioNoteChange(product.id, {
                                      title: i.title, 
                                      option,
                                      target: 'advancedRuleExtraNotesSetting'
                                    });
                                  }}>{option}</Radio>
                                })}
                              </Radio.Group>
                            )}
                          </FormItem>
                        </Col>
                      </Row>
                    }
                  })}
                </Col>
              </Row>
              <React.Fragment>
                { insertDivider }
              </React.Fragment>
            </React.Fragment>
          )
        return productElement;
      });

    return(
      <React.Fragment>
        {(this.state.showProducts && !this.state.shouldHideStoreWideExchangesModal) && 
        <StoreWideProducts
          {...this.props}
          visible={this.state.showProducts}
          hideProducts={this.hideProducts}
          handleSelectedProduct={this.handleSelectedProduct}
          currentProduct={this.state.currentPoduct}
          itemQuantity={this.state.itemQuantity}
        />}
        <Card
          title={
            <FormattedMessage
              id="app.visitor.order.headline"
              description="Title of the second step of the customer portal process ('Order')"
              defaultMessage="Select products to return"
              values={{
              }}
            />
          }
          className="textCenter"
        >
          {/* This will open drawer of the products to select for advance exchanges */}
          {this.state.exchangeOptionsVisible ? (
            <ExchangeOptions
              {...this.props}
              lineItemIdToBeExchanged={this.state.advancedExchangeLineItemId}
              showProductsDrawer={this.showExchangeOptions.bind(this)}
              companyIdentifier={this.props.companyIdentifier}
              returnNumber={this.props.returnNumber}
              updateExchangedProduct={this.updateExchangedProduct.bind(this)}
              advancedExchangeOptions={
                this.state.selectedAdvancedExchangeOptions
              }
              visible={this.state.exchangeOptionsVisible}
              closeExchangeOptions={this.closeExchangeOptions.bind(this)}
              currentProduct={this.state.currentPoduct}
            />
          ) : null}
          <Form onSubmit={this.handleSubmit}>
            {productList}
            <Divider />
            <FormItem>
              <Row type="flex" justify="center" align="top">
                <Col>
                  <Button type="primary" id="order" htmlType="submit" disabled={this.state.submitButtonDisabled} style={{ backgroundColor: this.props.brandColorButton, borderColor: this.props.brandColorButton }}>
                    <span style={{ color: this.props.brandColorButtonText, fontStyle: this.props.brandFontStyle }}>
                      <FormattedMessage
                        id="app.visitor.order.buttonSubmit"
                        description="Button to continue from step 'order' to 'resolution'"
                        defaultMessage="Continue"
                        values={{
                        }}
                      />
                    </span>
                  </Button>
                </Col>
              </Row>
            </FormItem>
          </Form>
        </Card>
      </React.Fragment>
    );
  }
}

const exchangeResolutionTranslation = {
  exchange: {
    title: (
      <FormattedMessage
        id="app.visitor.resolutions.exchange"
        description="Title of the resolution Exchange"
        defaultMessage="Exchange"
      />
    )
  }
}

const resolutionOptionsDetail = {
  storeCredit: {
    title: (
      <FormattedMessage
        id="app.visitor.resolutions.storeCreditTitle"
        description="Title of the refund method: store credit"
        defaultMessage="Store credit"
      />
    ),
    identifier: "storeCredit",
    description: (
      <FormattedMessage
        id="app.visitor.resolutions.storeCreditDescription"
        description="Description of the refund method: store credit"
        defaultMessage="We'll apply the refund to your account."
      />
    ),
    refundTime: (
      <FormattedMessage
        id="app.visitor.resolutions.storeCreditRefundTime"
        description="Description of the refund method: store credit"
        defaultMessage="Estimated refund timing: Instant once we receive the original item(s)."
      />
    ),
  },
  exchangeItem: {
    identifier: "exchangeItem",
    title: (
      <FormattedMessage
        id="app.visitor.resolutions.exchangeItemTitle"
        description="Title of the refund method: exchange"
        defaultMessage="Exchange for a different variant"
      />
    ),
    description: (
      <FormattedMessage
        id="app.visitor.resolutions.exchangeItemDescription"
        description="Description of the refund method: exchange"
        defaultMessage="We'll exchange the item(s) if available."
      />
    ),
    refundTime: (
      <FormattedMessage
        id="app.visitor.resolutions.exchangeItemRefundTime"
        description="Description of the refund method: exchange"
        defaultMessage="Estimated refund timing: Instant once we receive the original item(s)."
      />
    ),
  },
  storeWideExchange: {
    identifier: "storeWideExchange",
    title: (
      <FormattedMessage
        id="app.visitor.resolutions.storeWideExchangeTitle"
        description="Title of the refund method: storeWideExchange"
        defaultMessage="Exchange for a different product store-wide"
      />
    ),
    description: (
      <FormattedMessage
        id="app.visitor.resolutions.storeWideExchangeDescription"
        description="Description of the refund method: storeWideExchange"
        defaultMessage="We'll exchange the item(s) if available."
      />
    ),
    refundTime: (
      <FormattedMessage
        id="app.visitor.resolutions.storeWideExchangeRefundTime"
        description="Description of the refund method: storeWideExchange"
        defaultMessage="Estimated refund timing: Instant once we receive the original item(s)."
      />
    ),
  },
  advancedExchange: {
    identifier: "advancedExchange",
    title: (
      <FormattedMessage
        id="app.visitor.resolutions.advancedExchangeOptionTitle"
        description="Title of the Advanced Exchange Option"
        defaultMessage="Exchange for a product from a similar collection"
      />
    ),
    description: (
      <FormattedMessage
        id="app.visitor.resolutions.advanceExchangeOptionDescription"
        description="Description of the refund method: exchange"
        defaultMessage="We'll exchange the item(s) if available."
      />
    ),
    refundTime: (
      <FormattedMessage
        id="app.visitor.resolutions.advanceExchangeOptionRefundTime"
        description="Description of the refund method: exchange"
        defaultMessage="Estimated refund timing: Instant once we receive the original item(s)."
      />
    ),
  },
  originalPaymentMethod: {
    identifier: "originalPaymentMethod",
    title: (
      <FormattedMessage
        id="app.visitor.resolutions.originalPaymentMethodTitle"
        description="Title of the refund method: original payment"
        defaultMessage="Refund to original payment method"
      />
    ),
    description: (
      <FormattedMessage
        id="app.visitor.resolutions.originalPaymentMethodDescription"
        description="Description of the refund method: original payment"
        defaultMessage="We'll process your refund to your original payment method."
      />
    ),
    refundTime: (
      <FormattedMessage
        id="app.visitor.resolutions.originalPaymentMethodRefundTime"
        description="Description of the refund method: original payment"
        defaultMessage="Estimated refund timing: 5-7 business days after we receive the original item(s)."
      />
    ),
  },
  refundToCustom1: {
    identifier: "refundToCustom1",
    title: "",
    description: "",
    refundTime: "",
  },
  refundToCustom2: {
    identifier: "refundToCustom2",
    title: "",
    description: "",
    refundTime: "",
  },
  refundToCustom3: {
    identifier: "refundToCustom3",
    title: "",
    description: "",
    refundTime: "",
  }
}

const WrappedOrder = Form.create({})(Order);
export default injectIntl(WrappedOrder);
